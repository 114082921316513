import React from "react";
import DateFormatComponent from "../../../common/components/DateFormatComponent.jsx";
import DateTimeFormatComponent from "../../../common/components/DateTimeFormatComponent.jsx";
import OrderStatusFormat from "../../../common/components/order/OrderStatusFormat.jsx";

export default class MawOrderTableRowComponent extends React.Component {
	constructor() {
		super();
	}

	render() {
		let order = this.props.order;
		const orderDate = order.orderDate.slice(0, 10);
		return (
			<tr>
				<td>
					<input
						type="checkbox"
						checked={this.props.isChecked}
						className="oCheckBox"
						value={order.imCollaborationId}
						onChange={this.props.selectOrder}
					/>
				</td>

				<td className="orNumCol">
					<p className="text-info">{order.imCollaborationId}</p>
					<DateTimeFormatComponent
						format="最新編集日時：yyyy/MM/dd HH:mm:ss"
						data={order.updatedAt}
						className="date-time"
					/>
				</td>

				<td>
					<DateFormatComponent data={order.orderDate} />
				</td>

				<td className="orSttCol">
					<OrderStatusFormat data={order.status} />
				</td>

				<td>{order.expectedShipDate}</td>
			</tr>
		);
	}
}
