/**
 * Created by hoangnv on 1/12/17.
 */
import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import Button from 'react-bootstrap/lib/Button'

export default class ConfirmDialog extends React.Component {

  constructor () {
    super()
    this.accept = this.accept.bind(this)
    this.reject = this.reject.bind(this)
  }

  accept () {
    this.props.close()
    this.props.accept()
  }

  reject () {
    this.props.close()
    if (this.props.reject) {
      this.props.reject()
    }
  }

  render () {
    return (
      <Modal show={this.props.showModal} onHide={this.props.close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="ng-binding">{this.props.message}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.accept} bsStyle="warning"
                  className="btn-raised">{this.props.acceptTitle
            ? this.props.acceptTitle
            : 'はい'}</Button>
          <Button onClick={this.reject} bsStyle="default"
                  className="btn-raised">{this.props.rejectTitle
            ? this.props.rejectTitle
            : 'いいえ'}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
