import React from 'react'
import OrderConstant from '../OrderConstant.jsx'
import OrderAction from '../OrderAction.jsx'
import Button from 'react-bootstrap/lib/Button'

export default class OrderStatusBarComponent extends React.Component {
  constructor () {
    super()
    this.renderStatusBar = this.renderStatusBar.bind(this)
    this.getStatusBarInfo = this.getStatusBarInfo.bind(this)
    this.componentStyle = {
      marginTop: '10px',
      overflow: 'hidden',
    }
    this.statusUlStyle = {
      float: 'left',
      marginBottom: "7px"
    }
    this.exportButtonStyle = {
      margin: 0,
      marginBottom: "5px"
    }
    this.statusGroupStyle = {
      display: 'inline-block'
    }
    this.selectedOrderInfoStyle = {
      padding: "3px 10px"
    }
  }

  filterByStatus (status) {
    OrderAction.changeStatus(status)
  }

  selectChangeStatus (event) {
    OrderAction.changeStatusWhenExportCSV(event.target.checked)
  }

  render () {
    return (
      <div style={this.componentStyle}>
        <div style={this.statusGroupStyle}>
          <ul className="nav nav-pills" style={this.statusUlStyle}>
            {this.renderStatusBar()}
          </ul>
          {
            this.props.selectedOrders.length > 0 ?
              <div style={this.selectedOrderInfoStyle}><span className="text-danger">{this.props.selectedOrders.length}</span>注文が選択されました。</div> : null
          }
        </div>
          <div className={"export-csv-box"}>
            <button onClick={this.props.downloadShipmentCsv}
               bsStyle="default" className="btn-raised btn-sm btn-info row"
               style={this.exportButtonStyle}>納品書・出荷用CSV一括ダウンロード</button>
            {
              this.props.orderFilter.status === OrderConstant.STATUS.NEW ?
                <div>
                  <input id="selectNotChangeStatus" type="checkBox"
                    checked={this.props.isChecked}
                    onChange={this.selectChangeStatus}/><label htmlFor="selectNotChangeStatus">制作中に変更しない</label>
                </div>
              :
              null
            }
          </div>
      </div>
    )
  }

  renderStatusBar () {
    let self = this
    return Object.keys(OrderConstant.STATUS).map(function (key) {
      let status = OrderConstant.STATUS[key]
      let statusBarInfo = self.getStatusBarInfo(status)
      return (
        <li key={key} className={status === self.props.orderFilter.status
          ? 'active'
          : ''}>
          <a onClick={self.filterByStatus.bind(self, status)}>
            {statusBarInfo.text}
            <span className="text-warning"> {statusBarInfo.numberOfOrders
              ? statusBarInfo.numberOfOrders
              : 0}件</span>
          </a>
        </li>
      )
    })
  }

  getStatusBarInfo (status) {
    return {
      numberOfOrders: this.props.ordersDTO[status],
      text: OrderConstant.STATUS_JP[status.toUpperCase()],
    }
  }
}
