/**
 * Created by hoangnv on 1/9/17.
 */

import React from 'react'

export default class LoadingButtonComponent extends React.Component {
  constructor (props) {
    super()
    this.state = this.getState(props)
  }

  getState (props) {
    return {
      className: props.className + ' ' + (props.isLoading ? 'loading-btn' : ''),
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState(this.getState(nextProps))
  }

  render () {
    return <button type="button" className={this.state.className}
                   onClick={this.props.onClick} disabled={this.props.disabled}>
      {this.props.children}<span className="loading-icon"></span>
    </button>
  }

}