import React from 'react'
import OrderTableRowComponent from './OrderTableRowComponent.jsx'
import OrderService from '../OrderService.jsx'
import OrderAction from '../OrderAction.jsx'
import { Right } from 'react-bootstrap/lib/Media'

export default class OrderTableComponent extends React.Component {
  constructor () {
    super()
    this.isChecked = this.isChecked.bind(this)
    this.isCheckAll = this.isCheckAll.bind(this)
  }

  render () {
    return (
      <div className="tbl-admin tbl-template">
        <table className="table table-striped table-hover table-bordered">
          <thead>
          <tr>
            <th>
              <input type="checkbox" checked={this.isCheckAll()} onChange={this.checkAll}/>
            </th>
            <th className="idColHd">連携オーダーID</th>
            <th className="idColHd">パートナー注文番号</th>
            <th>出荷予定日 
              <button onClick={this.onSortChange.bind(this, 1)} style={{background: '#7e7e7e'}}>
								<i className={`fa fa-${this.props.orderFilter.orderByExShipDate === 'DESC' ? 'sort-down' : this.props.orderFilter.orderByExShipDate === 'ASC' ? 'sort-up' : 'sort'}`}/>
							</button>
            </th>
            {/* <th>到着予定日 
              <button onClick={this.onDeliveryDateSortChange} style={{background: '#7e7e7e'}}>
								<i className={`fa fa-${this.state.currentDeliveryDateSort}`} />
							</button>
            </th> */}
            <th>注文日時</th>
            <th>ステータス</th>
          </tr>
          </thead>
          <tbody>
          {this.orderRows}
          </tbody>
        </table>
      </div>
    )
  }

  isCheckAll(){
    if(!this.props.orders)
      return false;

    let isCheckAll = true;
    let selectedOrders = this.props.selectedOrders
    this.props.orders.forEach(function (order) {
      isCheckAll = isCheckAll && $.inArray("" + order.imCollaborationId, selectedOrders) !== -1
    })

    return isCheckAll;
  }

  selectOrder(event){
    OrderService.selectOrders([event.target.value], event.target.checked)
  }

  checkAll(event){
    let uncheckOrders = []
    $("input:checkbox[class=oCheckBox]:not(:checked)").each(function(){
      uncheckOrders.push($(this).val());
    });

    let allOrders = []
    $("input:checkbox[class=oCheckBox]").each(function(){
      allOrders.push($(this).val());
    });

    if(event.target.checked){
      OrderService.selectOrders(uncheckOrders, event.target.checked)
    } else {
      OrderService.selectOrders(allOrders, event.target.checked)
    }

  }

  isChecked(orderNumber){
    if(!this.props)
      return false
    return $.inArray("" + orderNumber, this.props.selectedOrders) !== -1
  }

  get orderRows () {
    var orders = this.props.orders
    var loadDetailOrder = this.props.loadDetailOrder
    var self = this;
    if (orders.length > 0) {
      if (this.props.orderFilter.orderByDefaultDeliDate){
        var groupByCurrentDeliDate = {}
        orders.forEach(function (order){
          if(groupByCurrentDeliDate[order.defaultExpectedDeliveryDate]){
            groupByCurrentDeliDate[order.defaultExpectedDeliveryDate].push(order)
          } else {
            groupByCurrentDeliDate[order.defaultExpectedDeliveryDate] = [order]
          }
        })
        var returnData = []
        $.each( groupByCurrentDeliDate, function( key, value ) {
          returnData.push(
            <tr key={key}>
              <td colSpan={6}>
                <span className="text-danger">{key}</span>に到着予定日の注文
              </td>
            </tr>
          )

          value.forEach(function (order) {
            returnData.push(<OrderTableRowComponent key={order.orderNumber}
                                                    order={order}
                                                    loadDetailOrder={loadDetailOrder}
                                                    selectOrder={self.selectOrder}
                                                    isChecked = {self.isChecked(order.imCollaborationId)}/>)
          })
        });
        return (returnData);
      } else {
        var groupByExShipDate = {}
        orders.forEach(function (order) {
          if(groupByExShipDate[order.expectedShipDate]){
            groupByExShipDate[order.expectedShipDate].push(order)
          } else {
            groupByExShipDate[order.expectedShipDate] = [order]
          }
        })
        var returnData = [];
        $.each( groupByExShipDate, function( key, value ) {
          returnData.push(
            <tr key={key}>
              <td colSpan={6}>
                <span className="text-danger">{key}</span>に出荷予定日の注文
              </td>
            </tr>
          )

          value.forEach(function (order) {
            returnData.push(<OrderTableRowComponent key={order.orderNumber}
                                                    order={order}
                                                    loadDetailOrder={loadDetailOrder}
                                                    selectOrder={self.selectOrder}
                                                    isChecked = {self.isChecked(order.imCollaborationId)}/>)
          })
        });
        return (returnData);
      }
    }
    else
      return <tr>
        <td colSpan="8">{this.props.orderFilter.keyword ?
          <span>検索結果は0件です。</span> : <span>注文は0件です。</span>}</td>
      </tr>
  }

  onSortChange(type) {
    var orderByExShipDate = null
    var orderByDefaultDeliDate = null
    if (type === 1) {
      switch (this.props.orderFilter.orderByExShipDate) {
        case 'DESC': 
          orderByExShipDate = 'ASC'
          break;
        case 'ASC':
          orderByExShipDate = 'DESC'
          break;
        default:
          orderByExShipDate = 'DESC'
          break; 
      }
    } else if (type === 2) {
      switch (this.props.orderFilter.orderByDefaultDeliDate){
        case 'DESC': 
          orderByDefaultDeliDate = 'ASC'
          break;
        case 'ASC':
          orderByDefaultDeliDate = 'DESC'
          break;
        default:
          orderByDefaultDeliDate = 'DESC'
          break;
      }
    }

    OrderAction.changeOrderSortType(orderByExShipDate, orderByDefaultDeliDate)
  }

}