import React from 'react'
import DateTimeFormatComponent from '../../common/components/DateTimeFormatComponent.jsx'
import OrderStatusFormat
  from '../../common/components/order/OrderStatusFormat.jsx'

export default class OrderTableRowComponent extends React.Component {
  constructor () {
    super()
  }

  render () {
    let order = this.props.order
    let orderNumber = order.orderNumber
    return (
      <tr>
        <td><input type="checkbox"
                   checked={this.props.isChecked}
                   className={"oCheckBox"}
                   value={order.imCollaborationId} onChange={this.props.selectOrder}/></td>
        <td className="orNumCol">
          <a onClick={this.props.loadDetailOrder.bind(this, orderNumber)}
             className="text-info link-pointer">{order.imCollaborationId}</a>
          <DateTimeFormatComponent format="最新編集日時：yyyy/MM/dd HH:mm:ss"
                                   data={order.updatedAt} className="date-time"/>
        </td>
        <td className="orNumCol">{orderNumber}</td>
        <td>{order.expectedShipDate}</td>
        {/* <td>{order.defaultExpectedDeliveryDate ? order.defaultExpectedDeliveryDate : ''}</td> */}
        <td><DateTimeFormatComponent data={order.orderDate}/></td>
        <td className="orSttCol"><OrderStatusFormat data={order.status}/></td>
      </tr>
    )
  }
}