/**
 * Created by thangkc on 26/01/2016.
 */
import React from 'react'

export default class DateFormatComponent extends React.Component {
  constructor () {
    super()
  }

  render () {
    function formatDate (dateISO8601) {
      var dateISO8601Regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(.\d*)?[+-](\d{2})\:(\d{2})/
      if (dateISO8601 == undefined ||
        !dateISO8601.match(dateISO8601Regex)) return ''

      const date = new Date(dateISO8601)
      var year = '' + date.getFullYear()
      var month = '' + (date.getMonth() + 1)
      if (month.length == 1) {
        month = '0' + month
      }
      var day = '' + date.getDate()
      if (day.length == 1) {
        day = '0' + day
      }

      return year + '/' + month + '/' + day
    }

    return (
      <span>
                {formatDate(this.props.data)}
            </span>
    )
  }
}