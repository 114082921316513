/**
 * Created by thangkc on 26/01/2016.
 */
import React from 'react'

export default class DateTimeFormatComponent extends React.Component {
  constructor () {
    super()
  }

  render () {
    function formatDate (dateISO8601, format) {
      var dateISO8601Regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(.\d*)?([+-](\d{2})\:(\d{2}))?/
      if (dateISO8601 == undefined ||
        !dateISO8601.match(dateISO8601Regex)) return ''

      const date = new Date(dateISO8601)
      var year = '' + date.getFullYear()
      var month = '' + (date.getMonth() + 1)
      if (month.length == 1) {
        month = '0' + month
      }
      var day = '' + date.getDate()
      if (day.length == 1) {
        day = '0' + day
      }
      var hour = '' + date.getHours()
      if (hour.length == 1) {
        hour = '0' + hour
      }
      var minute = '' + date.getMinutes()
      if (minute.length == 1) {
        minute = '0' + minute
      }
      var second = '' + date.getSeconds()
      if (second.length == 1) {
        second = '0' + second
      }

      if (format)
        return format.replace(/yyyy/g, year).
          replace(/MM/g, month).
          replace(/dd/g, day).
          replace(/HH/g, hour).
          replace(/mm/g, minute).
          replace(/ss/g, second)

      return year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' +
        second
    }

    return (
      <span className={this.props.className}>
                {formatDate(this.props.data, this.props.format)}
            </span>
    )
  }
}