/**
 * Created by thangkc on 14/12/2015.
 */
import React from "react";
import ReactLinkedStateMixin from "react-addons-linked-state-mixin";
import ReactMixin from "react-mixin";
import LoadingButtonComponent from "../common/components/LoadingButtonComponent.jsx";
import LoginService from "../common/services/AuthenticationService.jsx";
import LoginStore from "../common/stores/AuthenticationStore.jsx";

export default class LoginComponent extends React.Component {
	constructor() {
		super();
		this.state = this._getState();
		this._onChange = this._onChange.bind(this);
	}

	_getState() {
		return {
			userLoggedIn: LoginStore.loggedInUser(),
			errors: LoginStore.LoginError(),
			loading: LoginStore.isLoading(),
		};
	}

	componentDidMount() {
		if (this.state.userLoggedIn) {
			this.props.history.pushState(null, "/orders");
		} else {
			LoginService.isAuthenticated();
		}
		LoginStore.addChangeListener(this._onChange);

		const self = this;
		$(document).keypress(function (e) {
			if (e.key == "Enter") {
				self.handleLoginClick(e);
			}
		});
	}

	componentWillUnmount() {
		this.setState({});
		LoginStore.removeChangeListener(this._onChange);
	}

	componentDidUpdate() {
		if (LoginStore.loggedInUser()) {
			this.props.history.pushState(null, "/orders");
		}
	}

	_onChange() {
		this.setState(this._getState());
	}

	render() {
		return (
			<div className="login-frm">
				<div className="flat-form">
					<h2>ログイン</h2>
					<div className="form-action show" id="login">
						<form>
							{this.errorMessage}
							<ul className="list-unstyled">
								<li>
									<input
										type="text"
										placeholder="ログインID"
										className="form-control"
										valueLink={this.linkState("name")}
									/>
								</li>
								<li>
									<input
										type="password"
										placeholder="パスワード"
										className="form-control"
										valueLink={this.linkState("password")}
									/>
								</li>
							</ul>
							<LoadingButtonComponent
								isLoading={this.state.loading}
								className="btn-raised btn btn-info btn-lg"
								onClick={this.handleLoginClick.bind(this)}
							>
								ログイン
							</LoadingButtonComponent>
						</form>
					</div>
				</div>
			</div>
		);
	}

	get errorMessage() {
		if (this.state.errors) {
			return (
				<div className="text-danger form-group error">
					アカウントIDまたはパスワードが違います。再入力してください。
				</div>
			);
		}
	}

	handleLoginClick(e) {
		e.preventDefault();
		LoginService.login(this.state.name, this.state.password);
	}
}

ReactMixin(LoginComponent.prototype, ReactLinkedStateMixin);
