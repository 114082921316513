/**
 * Created by PhuNH on 16/12/21.
 */
import React from 'react'
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin'
import ReactMixin from 'react-mixin'

export default class PagingRenderer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {page: 1}
  }

  componentWillReceiveProps (nextProps) {
    let currentPage = nextProps.currentPage
    currentPage = (typeof currentPage === 'number' ? currentPage : parseInt(
      currentPage)) + (1 - nextProps.basedIndex)
    this.setState({page: currentPage})
  }

  render () {
    /*
     * basedIndex
     * currentPage
     * maxPage
     * changePage
     * previous
     * next
     * component
     */
    const END_LEN = 3, AROUND_LEN = 2, ELLIPSIS_LEN = 2

    let basedIndex = this.props.basedIndex,
      currentPage = this.props.currentPage, maxPage = this.props.maxPage
    currentPage = (typeof currentPage === 'number' ? currentPage : parseInt(
      currentPage)) + (1 - basedIndex)
    maxPage = typeof maxPage === 'number' ? maxPage : parseInt(maxPage)
    let changePage = this.props.changePage
    let component = this.props.component

    let previous = '', next = ''
    if (currentPage > 1 && maxPage > 0)
      previous =
        <li key="previous"><a onClick={this.props.previous}>&laquo;</a></li>
    if (currentPage !== maxPage && maxPage > 0)
      next = <li key="next"><a onClick={this.props.next}>&raquo;</a></li>

    let maxPageNoEllipsis = Math.max(END_LEN, AROUND_LEN + 1) * 2 +
      ELLIPSIS_LEN - 1,
      maxPageNoMid = (END_LEN + ELLIPSIS_LEN + AROUND_LEN) * 2
    let pushUncertainOption = function (options, index) {
      options.push(
        <li className={currentPage === index ? 'active' : 'with-value'}
            key={index}>
          <a onClick={changePage.bind(component,
            index - (1 - basedIndex))}>{index}</a>
        </li>,
      )
    }, pushCertainValueOption = function (options, index) {
      options.push(
        <li className="with-value" key={index}>
          <a onClick={changePage.bind(component,
            index - (1 - basedIndex))}>{index}</a>
        </li>,
      )
    }, pushCertainNoValueOption = function (options, index) {
      options.push(
        <li className="without-value" key={index}>
          <a>...</a>
        </li>,
      )
    }

    let options = []
    let i = 1
    if (maxPage <= 1) options = []
    else if (maxPage <= maxPageNoEllipsis)
      for (; i <= maxPage; i++) pushUncertainOption(options, i)
    else if (maxPage <= maxPageNoMid) {
      let isCurrentPageAtLeft = currentPage <= END_LEN + ELLIPSIS_LEN +
        AROUND_LEN,
        maxLeft = isCurrentPageAtLeft ? currentPage + AROUND_LEN : END_LEN,
        minRight = isCurrentPageAtLeft ? maxPage - END_LEN + 1 : currentPage -
          AROUND_LEN
      if (maxLeft + ELLIPSIS_LEN >= minRight)
        for (; i <= maxPage; i++) pushUncertainOption(options, i)
      else {
        for (; i <= maxLeft; i++) pushUncertainOption(options, i)
        pushCertainNoValueOption(options, i)
        for (i = minRight; i <= maxPage; i++) pushUncertainOption(options, i)
      }
    } else {
      let isCurrentPageAtLeft = currentPage <= END_LEN + ELLIPSIS_LEN +
        AROUND_LEN,
        isCurrentPageAtRight = currentPage > maxPage - END_LEN - ELLIPSIS_LEN -
          AROUND_LEN
      if (isCurrentPageAtLeft || isCurrentPageAtRight) {
        let maxLeft = isCurrentPageAtLeft ? currentPage + AROUND_LEN : END_LEN,
          minRight = isCurrentPageAtLeft ? maxPage - END_LEN + 1 : currentPage -
            AROUND_LEN
        for (; i <= maxLeft; i++) pushUncertainOption(options, i)
        pushCertainNoValueOption(options, i)
        for (i = minRight; i <= maxPage; i++) pushUncertainOption(options, i)
      } else {
        let minRight = maxPage - END_LEN + 1
        for (; i <= END_LEN; i++) pushCertainValueOption(options, i)
        pushCertainNoValueOption(options, i)
        for (i = currentPage - AROUND_LEN; i <= currentPage +
        AROUND_LEN; i++) pushUncertainOption(options, i)
        pushCertainNoValueOption(options, i)
        for (i = minRight; i <= maxPage; i++) pushCertainValueOption(options,
          i)
      }
    }

    let jumpBox = []
    if (maxPage > 1) jumpBox =
      <div className="form-inline jump-box">
        <input className="form-control jump-box-num"
               type="number" pattern="/^[0-9]*$/"
               min="1" max={maxPage} valueLink={this.linkState('page')}/>
        <input className="btn btn-raised btn-info jump-box-btn"
               type="button" value="設定"
               disabled={this.state.page <= 0 || this.state.page > maxPage}
               onClick={changePage.bind(component,
                 parseInt(this.state.page) - (1 - basedIndex))}/>
      </div>

    return (
      <div className="paging-area">
        <ul className="pagination">
          {previous}
          {options}
          {next}
        </ul>
        {jumpBox}
      </div>
    )
  }
}

ReactMixin(PagingRenderer.prototype, ReactLinkedStateMixin)