const ReportConstant = {
  LOAD: 'LOAD_REPORT',
  SHOW_REPORT: 'SHOW_REPORT',
  ERROR: 'ERROR_REPORT',
  URL: '/api/report',
  ACTION_TYPE: {
    SHOW_PRODUCT_REPORT: 'SHOW_PRODUCT_REPORT',
  },
  API: {
    PRODUCT_REPORT_URL: '/api/reports/products',
  },
}

export default ReportConstant
