import React from 'react'

class CommonService {
  createReportTitle (start, end, reportType) {
    let formatValueDate = ''
    let formatKeyDate = ''
    let momentFormatType = ''
    if (reportType === 'DAY') {
      formatValueDate = 'YYYY年MM月DD日'
      formatKeyDate = 'YYYYMMDD'
      momentFormatType = 'days'
    } else {
      formatValueDate = 'YYYY年MM月'
      formatKeyDate = 'YYYYMM'
      momentFormatType = 'months'
    }

    var s = start
    var e = end

    var a = {}
    // While the updated start date is older, perform the loop.
    while (s.format(formatKeyDate) <= e.format(formatKeyDate)) {
      // Update the format according to moment js documentations format().
      let key = s.format(formatKeyDate)
      let title = s.format(formatValueDate)
      a[key] = title
      s = s.add(1, momentFormatType)
    }
    return a
  }

  createAnalyticsTitle (start, end, format) {
    let formatValueDate = format
    let formatKeyDate = 'YYYY-MM-DD'
    let momentFormatType = 'days'
    var s = start
    var e = end

    var a = {}
    // While the updated start date is older, perform the loop.
    while (s.format(formatKeyDate) <= e.format(formatKeyDate)) {
      // Update the format according to moment js documentations format().
      let key = s.format(formatKeyDate)
      let title = s.format(formatValueDate)
      a[key] = title
      s = s.add(1, momentFormatType)
    }
    return a
  }
}

export default new CommonService()
