import React from "react";
import MawOrderTableRowComponent from "./MawOrderTableRowComponent.jsx";
import OrderService from "../OrderService.jsx";
import OrderAction from "../OrderAction.jsx";
import OrderConstant from "../OrderConstant.jsx";

export default class MawOrderTableComponent extends React.Component {
	constructor() {
		super();
		this.isChecked = this.isChecked.bind(this);
		this.isCheckAll = this.isCheckAll.bind(this);
	}

	render() {
		return (
			<div className="tbl-admin tbl-template">
				<table className="table table-striped table-hover table-bordered">
					<thead>
						<tr>
							<th>
								<input
									type="checkbox"
									checked={this.isCheckAll()}
									onChange={this.checkAll}
								/>
							</th>

							<th className="idColHd">連携オーダーID</th>
							<th className="">印刷発注日</th>
							<th>ステータス</th>
							<th>
								出荷予定日
								{/* <button
									onClick={this.onSortChange.bind(this, 1)}
									style={{ background: "#7e7e7e" }}
								>
									<i
										className={`fa fa-${
											this.props.orderFilter.orderByExShipDate === "DESC"
												? "sort-down"
												: this.props.orderFilter.orderByExShipDate === "ASC"
												? "sort-up"
												: "sort"
										}`}
									/>
								</button> */}
							</th>
						</tr>
					</thead>
					<tbody>{this.orderRows}</tbody>
				</table>
			</div>
		);
	}

	isCheckAll() {
		if (!this.props.orders) return false;

		let isCheckAll = true;
		let selectedOrders = this.props.selectedOrders;
		this.props.orders.forEach(function (order) {
			isCheckAll =
				isCheckAll &&
				$.inArray("" + order.imCollaborationId, selectedOrders) !== -1;
		});

		return isCheckAll;
	}

	downloadOrderCsvByDate(targetDate) {
		OrderService.downloadOrderCsvByDate(targetDate, this.props.orderStatus);
	}

	selectOrder(event) {
		OrderService.selectOrders([event.target.value], event.target.checked);
	}

	checkAll(event) {
		let uncheckOrders = [];
		$("input[type='checkbox'].oCheckBox:not(:checked)").each(function () {
			uncheckOrders.push($(this).val());
		});

		let allOrders = [];
		$("input[type='checkbox'].oCheckBox").each(function () {
			allOrders.push($(this).val());
		});

		if (event.target.checked) {
			OrderService.selectOrders(uncheckOrders, event.target.checked);
		} else {
			OrderService.selectOrders(allOrders, event.target.checked);
		}
	}

	isChecked(orderNumber) {
		if (!this.props) return false;
		return $.inArray("" + orderNumber, this.props.selectedOrders) !== -1;
	}

	get orderRows() {
		var orders = this.props.orders;
		var self = this;
		if (orders.length > 0) {
			if (this.props.orderFilter.orderByDefaultDeliDate) {
				var groupByCurrentDeliDate = {};
				orders.forEach(function (order) {
					if (groupByCurrentDeliDate[order.defaultExpectedDeliveryDate]) {
						groupByCurrentDeliDate[order.defaultExpectedDeliveryDate].push(
							order
						);
					} else {
						groupByCurrentDeliDate[order.defaultExpectedDeliveryDate] = [order];
					}
				});
				var returnData = [];
				$.each(groupByCurrentDeliDate, function (deliDate, value) {
					returnData.push(
						<tr key={deliDate}>
							<td colSpan={6}>
								<button type="button"></button>
								<span className="text-danger">{deliDate}</span>
								に発注された印刷オーダー
							</td>
						</tr>
					);

					value.forEach(function (order) {
						returnData.push(
							<MawOrderTableRowComponent
								key={order.orderNumber}
								order={order}
								selectOrder={self.selectOrder}
								isChecked={self.isChecked(order.imCollaborationId)}
							/>
						);
					});
				});
				return returnData;
			} else {
				var ordersGroupedByDate = {};
				orders.forEach(function (order) {
					if (ordersGroupedByDate[order.orderDate.slice(0, 10)]) {
						ordersGroupedByDate[order.orderDate.slice(0, 10)].push(order);
					} else {
						ordersGroupedByDate[order.orderDate.slice(0, 10)] = [order];
					}
				});
				var returnData = [];
				var orderIndex = 1;

				// ordersGroupedByDate by keys
				ordersGroupedByDate = Object.keys(ordersGroupedByDate)
					.sort((a, b) => new Date(b) - new Date(a))
					.reduce((obj, key) => {
						obj[key] = ordersGroupedByDate[key];
						return obj;
					}, {});

				$.each(ordersGroupedByDate, function (orderDate, ordersByDate) {
					returnData.push(
						<tr key={orderDate}>
							<td colSpan={6}>
								<span className="text-danger">{orderDate}</span>
								に発注された印刷オーダー
								{self.props.orderFilter.status === OrderConstant.STATUS.NEW ||
								self.props.orderFilter.status ===
									OrderConstant.STATUS.CREATING ? (
									<button
										className="btn-raised btn-sm btn-info row"
										title={`日付${orderDate}のダウンロード`}
										onClick={() => self.downloadOrderCsvByDate(orderDate)}
										style={{
											backgroundColor: "#5cb85c",
											marginLeft: "10px",
										}}
									>
										当日CSVダウンロード
									</button>
								) : (
									<div></div>
								)}
							</td>
						</tr>
					);

					ordersByDate.forEach(function (order) {
						returnData.push(
							<MawOrderTableRowComponent
								key={order.orderNumber}
								arrayIndex={orderIndex}
								order={order}
								selectOrder={self.selectOrder}
								isChecked={self.isChecked(order.imCollaborationId)}
							/>
						);
					});
					orderIndex++;
				});
				return returnData;
			}
		} else
			return (
				<tr>
					<td colSpan="8">
						{this.props.orderFilter.keyword ? (
							<span>検索結果は0件です。</span>
						) : (
							<span>注文は0件です。</span>
						)}
					</td>
				</tr>
			);
	}

	onSortChange(type) {
		var orderByExShipDate = null;
		var orderByDefaultDeliDate = null;
		if (type === 1) {
			switch (this.props.orderFilter.orderByExShipDate) {
				case "DESC":
					orderByExShipDate = "ASC";
					break;
				case "ASC":
					orderByExShipDate = "DESC";
					break;
				default:
					orderByExShipDate = "DESC";
					break;
			}
		} else if (type === 2) {
			switch (this.props.orderFilter.orderByDefaultDeliDate) {
				case "DESC":
					orderByDefaultDeliDate = "ASC";
					break;
				case "ASC":
					orderByDefaultDeliDate = "DESC";
					break;
				default:
					orderByDefaultDeliDate = "DESC";
					break;
			}
		}

		OrderAction.changeOrderSortType(orderByExShipDate, orderByDefaultDeliDate);
	}
}
