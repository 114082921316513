/**
 * Created by thangkc on 20/09/2016.
 */
import React from "react";
import DesignImageFormat from "../../../../common/components/design/DesignImageFormat.jsx";
import DesignActionFormat from "../../../../common/components/design/DesignActionFormat.jsx";
import OrderStatusFormat from "../../../../common/components/order/OrderStatusFormat.jsx";
import Button from "react-bootstrap/lib/Button";
import FlashComponent from "../../../../common/components/FlashComponent.jsx";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog.jsx";
import Constants from "../../../../common/constants/Constants.jsx";
import OrderConstant from "../../OrderConstant.jsx";
import OrderStore from "../../OrderStore.jsx";
import OrderService from "../../OrderService.jsx";

export default class DesignInfoComponent extends React.Component {
	constructor(props) {
		super();
		this.state = this._getState(props);

		this.createImageMagicOrder = this.createImageMagicOrder.bind(this);
		this.resetCreatingImageMagicOrderResult =
			this.resetCreatingImageMagicOrderResult.bind(this);
		this.renderCreatingImageMagicOrderResult =
			this.renderCreatingImageMagicOrderResult.bind(this);
		this.showCreatingIMOrderWithPaymentDialog =
			this.showCreatingIMOrderWithPaymentDialog.bind(this);
		this.closeCreatingIMOrderWithPaymentDialog =
			this.closeCreatingIMOrderWithPaymentDialog.bind(this);
		this.showCreatingIMOrderWithoutPaymentDialog =
			this.showCreatingIMOrderWithoutPaymentDialog.bind(this);
		this.closeCreatingIMOrderWithoutPaymentDialog =
			this.closeCreatingIMOrderWithoutPaymentDialog.bind(this);
		this.verifyOrderPayment = this.verifyOrderPayment.bind(this);
		this.showCreatingIMOrderDialog = this.showCreatingIMOrderDialog.bind(this);

		this.resetOrderStatusChangeResult =
			this.resetOrderStatusChangeResult.bind(this);
		this._onChange = this._onChange.bind(this);
		this.toggleUpdate = this.toggleUpdate.bind(this);
		this.updateStatus = this.updateStatus.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.downloadInvoice = this.downloadInvoice.bind(this);

		this.hideCreationErrorDialog = this.hideCreationErrorDialog.bind(this);
		this.showChangingToPauseDialog = this.showChangingToPauseDialog.bind(this);
		this.closeChangingToPauseDialog =
			this.closeChangingToPauseDialog.bind(this);
	}

	_onChange() {
		this.setState(this._getState(this.props));
		this.showCreatingIMOrderDialog();
	}

	_getState(props) {
		return {
			crudMode: Constants.CRUD_MODE.READ,
			updateButtonTitle: "編集",
			selectedOrderStatus: props.order.printStatus,
			orderStatusChangeResult: OrderStore.getOrderStatusChangeResult(),
			isCreatingImageMagicOrder: false,
			createImageMagicOrderResult:
				OrderStore.getCreatingImageMagicOrderResult(),
			verifyOrderPaymentResult: OrderStore.getOrderPaymentResult(),
		};
	}

	componentDidMount() {
		OrderStore.addChangeListener(this._onChange);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selectedOrderStatus: nextProps.order.printStatus,
		});
	}

	componentWillUnmount() {
		OrderStore.removeChangeListener(this._onChange);
	}

	toggleUpdate() {
		if (this.state.crudMode === Constants.CRUD_MODE.READ) {
			this.setState({
				crudMode: Constants.CRUD_MODE.UPDATE,
				updateButtonTitle: "保存",
			});
		} else {
			if (this.state.selectedOrderStatus === OrderConstant.STATUS.PAUSE) {
				this.showChangingToPauseDialog();
			} else {
				this.updateStatus();
			}
		}
	}

	updateStatus() {
		this.setState({
			crudMode: Constants.CRUD_MODE.READ,
			updateButtonTitle: "編集",
			orderStatusChangeResult: {},
		});
		if (this.state.selectedOrderStatus !== this.props.order.printStatus) {
			OrderService.changeOrderStatus(
				this.props.order.orderNumber,
				this.state.selectedOrderStatus
			);
		}
	}

	resetOrderStatusChangeResult() {
		this.setState({
			orderStatusChangeResult: {},
		});
	}

	changeStatus(event) {
		this.setState({
			selectedOrderStatus: event.target.value,
		});
	}

	verifyOrderPayment() {
		this.setState({
			isCreatingImageMagicOrder: true,
			verifyOrderPaymentResult: {},
		});
		OrderService.verifyOrderPayment(this.props.order.orderNumber);
	}

	showCreatingIMOrderDialog() {
		if (this.state.verifyOrderPaymentResult.httpCode) {
			switch (this.state.verifyOrderPaymentResult.httpCode) {
				case 200:
					if (
						this.state.verifyOrderPaymentResult.paymentStatus ===
						OrderConstant.PAYMENT_STATUS.SETTLED
					) {
						this.showCreatingIMOrderWithPaymentDialog();
					} else {
						this.showCreatingIMOrderWithoutPaymentDialog();
					}
					break;
				default:
					this.showCreatingIMOrderWithoutPaymentDialog();
					break;
			}
		} else {
			return null;
		}
	}

	showCreatingIMOrderWithPaymentDialog() {
		this.setState({
			showCreatingIMOrderWithPaymentDialog: true,
			showCreatingIMOrderWithoutPaymentDialog: false,
		});
	}

	closeCreatingIMOrderWithPaymentDialog() {
		this.setState({
			showCreatingIMOrderWithPaymentDialog: false,
		});
	}

	showCreatingIMOrderWithoutPaymentDialog() {
		this.setState({
			showCreatingIMOrderWithPaymentDialog: false,
			showCreatingIMOrderWithoutPaymentDialog: true,
		});
	}

	closeCreatingIMOrderWithoutPaymentDialog() {
		this.setState({
			showCreatingIMOrderWithoutPaymentDialog: false,
		});
	}

	showCreationErrorDialog(message) {
		this.setState({
			creationErrorDialogShown: true,
			creationError: message,
		});
	}

	hideCreationErrorDialog() {
		this.setState({
			creationErrorDialogShown: false,
		});
	}

	createImageMagicOrder() {
		this.setState({
			isCreatingImageMagicOrder: true,
			createImageMagicOrderResult: {},
		});
		OrderService.createImageMagicOrder(
			this.props.order.orderNumber,
			Date.parse(this.props.order.updatedAt)
		);
	}

	resetCreatingImageMagicOrderResult() {
		this.setState({
			createImageMagicOrderResult: {},
		});
	}

	showChangingToPauseDialog() {
		this.setState({
			isShowChangingToPauseDialog: true,
		});
	}

	closeChangingToPauseDialog() {
		this.setState({
			isShowChangingToPauseDialog: false,
		});
	}

	downloadInvoice(order) {
		let URL = "/api/order/" + order.orderNumber + "/invoice";
		fetch(URL, {
			headers: {
				"Csrf-Token": "nocheck",
				Authorization: localStorage.getItem("token")
					? localStorage.getItem("token")
					: "",
			},
		}).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = "invoice_" + order.randomId + ".pdf";
				a.click();
			});
			//window.location.href = response.url;
		});
	}

	render() {
		let order = this.props.order;

		return (
			<div>
				<div className="clearfix">
					<h4 className="title pull-left">デザインツール情報</h4>
					<div className="clearfix pull-right">
						{this.renderUpdateOrderStatusMessage()}
						<Button
							bsStyle={
								this.state.crudMode === Constants.CRUD_MODE.READ
									? "info"
									: "success"
							}
							className="btn-raised"
							onClick={this.toggleUpdate}
							disabled={this.state.isCreatingImageMagicOrder}
						>
							{this.state.updateButtonTitle}
						</Button>
						<ConfirmDialog
							showModal={this.state.isShowChangingToPauseDialog}
							accept={this.updateStatus}
							close={this.closeChangingToPauseDialog}
							message="注文ステータスを「制作停止」に変更されてもよろしいでしょうか？"
						/>
					</div>
				</div>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th width="190px">デザインステータス</th>
							<td>
								{this.state.crudMode === Constants.CRUD_MODE.READ ? (
									<OrderStatusFormat
										data={this.state.selectedOrderStatus}
										renderOption={false}
									/>
								) : (
									this.renderOrderStatusSelectBox(order.printStatus)
								)}
							</td>
						</tr>
					</tbody>
				</table>
				<div>
					<div className="col-md-8" />
					<div className="col-md-4 invoice">
						<table className="table table-bordered">
							<tbody>
								<tr>
									<th>納品書データ</th>
									<td>
										<div>
											<button
												className="btn btn-raised btn-info"
												onClick={() => this.downloadInvoice(order)}
											>
												<i className="fa fa-download" />
												納品書
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="clearfix">
					<h4 className="title pull-left">デザイン情報</h4>
					<div className="clearfix pull-right">
						<ConfirmDialog
							showModal={this.state.showCreatingIMOrderWithPaymentDialog}
							accept={this.createImageMagicOrder}
							close={this.closeCreatingIMOrderWithPaymentDialog}
							message="ご制作を進めてもよろしいでしょうか？"
						/>
						<ConfirmDialog
							showModal={this.state.showCreatingIMOrderWithoutPaymentDialog}
							accept={this.createImageMagicOrder}
							close={this.closeCreatingIMOrderWithoutPaymentDialog}
							message="注文のキャリア決済支払未完了です。注文制作開始してもよろしいでしょうか？"
							acceptTitle="制作開始"
							rejectTitle="キャンセル"
						/>
					</div>
				</div>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>No.</th>
							<th>サムネイル・商品名</th>
							<th>素材</th>
							<th>時計の形状</th>
							<th>時計のサイズ</th>
							<th>ムーブメント</th>
							<th>時分針カラー</th>
							<th>秒針カラー</th>
							<th>時計のオプション</th>
							<th>数量</th>
							<th width="90px">アクション</th>
						</tr>
					</thead>
					<tbody>{this.designRows}</tbody>
				</table>
			</div>
		);
	}

	renderOrderStatusSelectBox(status) {
		let options = [];
		if (status === OrderConstant.STATUS.CANCEL) {
			options.push(
				<OrderStatusFormat
					data={OrderConstant.STATUS.CANCEL}
					renderOption={true}
					key={OrderConstant.STATUS.CANCEL}
				/>
			);
		} else if (status === OrderConstant.STATUS.SHIPPED) {
			options.push(
				<OrderStatusFormat
					data={OrderConstant.STATUS.SHIPPED}
					renderOption={true}
					key={OrderConstant.STATUS.SHIPPED}
				/>
			);
		} else {
			options.push(
				<OrderStatusFormat
					data={OrderConstant.STATUS.NEW}
					renderOption={true}
					key={OrderConstant.STATUS.NEW}
					visible={!this.props.order.hasLineProduct}
				/>
			);
			options.push(
				<OrderStatusFormat
					data={OrderConstant.STATUS.CREATING}
					renderOption={true}
					key={OrderConstant.STATUS.CREATING}
					visible={!this.props.order.hasLineProduct}
				/>
			);
			options.push(
				<OrderStatusFormat
					data={OrderConstant.STATUS.CANCEL}
					renderOption={true}
					key={OrderConstant.STATUS.CANCEL}
				/>
			);
		}

		return (
			<select
				defaultValue={this.state.selectedOrderStatus}
				onChange={this.changeStatus}
			>
				{options}
			</select>
		);
	}

	get designRows() {
		let order = this.props.order;
		let isBusy = this.state.isCreatingImageMagicOrder;

		if (order.designs.length === 0)
			return (
				<tr>
					<td colSpan="7">デザインは０件です!</td>
				</tr>
			);

		let allDesigns = order.designs || [];

		let returnData = [];

		allDesigns.forEach(function (design, index) {
			let myclo = design.product.myClo;

			returnData.push(
				<tr key={design.randomId}>
					<td>
						{index + 1}/{allDesigns.length}
					</td>
					<td>
						<DesignImageFormat
							className={"col-lg-" + 12 + " designImage"}
							key={design.id}
							rowData={design}
						/>
						{design.product.name}
					</td>
					<td>{myclo.productMaterial}</td>
					<td>{myclo.productShape}</td>
					<td>{myclo.productSize}</td>
					<td>{myclo.productMovement}</td>
					<td>{myclo.productHourMinuteHandColor}</td>
					<td>{myclo.productSecondHandColor}</td>
					<td>
						{myclo.productOption1} <br /> {myclo.productOption2}
					</td>
					<td>{1}</td>
					<td>
						<DesignActionFormat
							rowData={design}
							isBusy={isBusy}
							orderNumber={order.randomId}
						/>
					</td>
				</tr>
			);
		});

		return returnData;
	}

	renderUpdateOrderStatusMessage() {
		if (this.state.orderStatusChangeResult.httpCode) {
			let result = null;
			switch (this.state.orderStatusChangeResult.httpCode) {
				case 200:
					result = {
						message: this.state.orderStatusChangeResult.message,
						className: "text-success",
					};
					break;
				default:
					result = {
						message: this.state.orderStatusChangeResult.message,
						className: "text-danger",
					};
					break;
			}
			return (
				<FlashComponent
					visible={true}
					timeout={5000}
					className={result.className}
					onHide={this.resetOrderStatusChangeResult}
				>
					{result.message}
				</FlashComponent>
			);
		} else {
			return null;
		}
	}

	renderCreatingImageMagicOrderResult() {
		if (this.state.createImageMagicOrderResult.httpCode) {
			let result;
			switch (this.state.createImageMagicOrderResult.httpCode) {
				case 200:
					result = {
						className: "text-success",
						message: "バックグラウンドで制作処理を行いました。",
					};
					break;
				default:
					switch (this.state.createImageMagicOrderResult.errorCode) {
						case OrderConstant.EXCEPTIONS.WRONG_PRODUCT_EXCEPTION:
						case OrderConstant.EXCEPTIONS.NOT_ENOUGH_DESIGN_EXCEPTION:
						case OrderConstant.EXCEPTIONS.SPARE_DESIGNS_EXCEPTION:
							if (
								this.state.createImageMagicOrderResult.data
									.differenceBetweenDesignsAndProducts < 0
							) {
								result = {
									className: "text-danger",
									message:
										this.props.order.orderNumber +
										"の商品は残り" +
										Math.abs(
											this.state.createImageMagicOrderResult.data
												.differenceBetweenDesignsAndProducts
										) +
										"点デザインが必要です",
								};
							} else {
								result = {
									className: "text-danger",
									message:
										this.props.order.orderNumber +
										"の注文は" +
										this.state.createImageMagicOrderResult.data
											.differenceBetweenDesignsAndProducts +
										"点のデザインが超過しています",
								};
							}
							break;
						case OrderConstant.EXCEPTIONS.ORDER_IS_CREATING_EXCEPTION:
						case OrderConstant.EXCEPTIONS.ORDER_IS_MODIFIED:
							result = {
								className: "text-danger",
								message: "注文情報が更新されています。最新の注文情報をご確認",
							};
							break;
						case OrderConstant.EXCEPTIONS.EC_UPDATE_ERROR:
						default:
							result = {
								className: "text-danger",
								message: "注文制作に失敗しました。",
							};
							break;
					}
					break;
			}
			return (
				<FlashComponent
					visible={true}
					timeout={5000}
					className={result.className}
					onHide={this.resetCreatingImageMagicOrderResult}
				>
					{result.message}
				</FlashComponent>
			);
		} else {
			return null;
		}
	}
}
