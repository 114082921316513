/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react'
import JapanPostalCodeComponent
  from '../../../common/components/JapanPostalCodeComponent.jsx'

export default class OrdererComponent extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onChange = this._onChange.bind(this)
  }

  _onChange () {
    this.setState(this._getState())
  }

  _getState () {
    return {}
  }

  ordererName (orderer) {
    return orderer.familyName + ' ' + orderer.firstName
  }

  ordererNameKana (orderer) {
    return orderer.familyNameKana + ' ' + orderer.firstNameKana
  }

  ordererAddress (orderer) {
    return (
      <div>
        <span>{orderer.prefecture}</span><br/>
        <span>{orderer.city}</span><br/>
        <span>{orderer.subAddress1}</span><br/>
        <span>{orderer.subAddress2}</span>
      </div>
    )
  }

  render () {
    var orderer = this.props.orderer
    var payMethod = this.props.payMethod
    return (
      <div className="col-md-6">
        <div className="clearfix">
          <h4 className="title pull-left">注文者情報</h4>
          {/*<button className="btn btn-raised btn-info pull-right" disabled={true}>編集</button>*/}
        </div>
        <table className="table table-bordered">
          <thead>
          <tr>
            <th colSpan="2">注文者情報</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th width="190px">氏名</th>
            <td>{this.ordererName(orderer)}</td>
          </tr>
          <tr>
            <th>氏名カナ</th>
            <td>{this.ordererNameKana(orderer)}</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>
              <JapanPostalCodeComponent postalCode={orderer.zipCode}/>
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{this.ordererAddress(orderer)}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{orderer.phoneNumber}</td>
          </tr>
          <tr>
            <th>性別・生年月日</th>
            <td>{orderer.sex}・{orderer.birthYear}/{orderer.birthMonth}/{orderer.birthDay}</td>
          </tr>
          <tr>
            <th>お支払方法</th>
            <td>{payMethod}</td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}