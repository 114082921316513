import BaseStore from '../base/stores/BaseStore.jsx'
import OrderConstant from './OrderConstant.jsx'
import DesignConstant from '../common/constants/DesignConstant.jsx'
import OrderService from './OrderService.jsx'

class OrderStore extends BaseStore {
  constructor() {
    super();
    this.resetMessage = this.resetMessage.bind(this);
    this.ordersDTO = {
      currentPage: 1,
      maxPage: 0,
      orders: [],
    };
    this.orderProcessData = null;
    this.orderInterval = null;
    this.loading = false;
    this.orderFilter = {};
    this.selectedOrders = [];
    this.notChangeStatusWhenExportFlag = false;
    this.resetOrderFilter();
    this.orderDetail = {
      status: '',
      orderer: {},
      receiver: {},
      createdUser: {},
      products: [],
      designs: [],
      packages: [],
      httpCode: {
        changeStatusCode: 200,
        changeShipmentInfoCode: 200,
      },
      messages: {
        changeStatusMessage: '',
        changeShipmentInfoMessage: '',
      },
    };
    this.requestResult = {
      httpCode: {},
      messages: {},
      errorCodes: {},
      data: {
        verifyOrderPayment: {},
      },
    };
    this.subscribe(() => this.handler.bind(this))
  }

  resetMessage() {
    this.selectedFile = undefined;
    this.errorMessage = null;
    this.successMessage = null;
    this.waringMessage = null;
    this.infoMessage = null;
  }

  handler(action) {
    switch (action.actionType) {
      case OrderConstant.LOAD:
        this.loading = true;
        this.resetMessage();
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SELECT_ORDER:
        this.selectedOrders = this.selectedOrders.concat(action.orderNumbers);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.UPLOAD_ORDERS_CSV:
        this.loading = false;
        this.resetMessage();
        this.successMessage = OrderConstant.ALERT.SUCCESS;
        this.orderProcessData = action.orderProcessData;
        this.orderInterval = setInterval(() => {
          OrderService.getShipmentProcessNumber(action.mycloShippedFileId);
        }, 2000);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.GET_ORDERS_PROCESS_DATA:
        this.resetMessage();
        this.orderProcessData = action.data;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.REMOVE_SELECTED_ORDER:
        let self = this;
        action.orderNumbers.forEach(function (value) {
          self.selectedOrders.splice($.inArray(value, self.selectedOrders), 1);
        });
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.LOADED_ORDERS:
        this.ordersDTO = action.ordersDTO;
        this.orderFilter = action.orderFilter;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.DETAIL:
        this.orderDetail = action.order;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SHOW_ORDER_DETAIL:
        this.viewDetail = true;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.HIDE_ORDER_DETAIL:
        this.viewDetail = false;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_KEYWORD:
        this.orderFilter.currentPage = 1;
        this.orderFilter.keyword = action.keyword;
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.ON_SEARCH_STATUS:
        this.orderFilter.currentPage = 1;
        this.orderFilter.status = action.status;
        if (action.status === OrderConstant.STATUS.SHIPPED) {
          this.orderFilter.orderByExShipDate = 'DESC'
        } else
        if (this.orderFilter.status !== OrderConstant.STATUS.ALL) {
          this.orderFilter.orderByExShipDate = 'ASC'
        } else {
          this.orderFilter.orderByExShipDate = null
        }
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.ON_SEARCH_PAGE:
        this.orderFilter.currentPage = action.currentPage;
        OrderService.loadOrders(this.orderFilter);
        break;
      case DesignConstant.ACTION_TYPE.DELETE:
        this.deleteDesign(action.deletedDesigns);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS:
        this.orderDetail = action.order;
        this.requestResult.httpCode.orderStatusChange = action.httpCode;
        this.requestResult.messages.orderStatusChange = action.message;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS_WHEN_EXPORT_CSV:
        this.notChangeStatusWhenExportFlag = action.status;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_EC_STATUS:
        this.orderDetail = action.orderForEc;
        this.setChangeStatusCodeAndMessageForEc(action.httpCodeForEc,
          action.messageForEc);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_SORT_TYPE:
        this.orderFilter.orderByExShipDate = action.orderByExShipDate
        this.orderFilter.orderByDefaultDeliDate = action.orderByDefaultDeliDate
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_SHIPMENT_INFO:

        this.orderDetail = action.order;
        this.requestResult.httpCode.shipmentInfoChange = action.httpCode;
        this.requestResult.messages.shipmentInfoChange = action.message;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_ROLLBACK_CHANGE_STATUS:
        this.requestResult.httpCode.orderStatusChange = action.httpCode;
        this.requestResult.messages.orderStatusChange = action.message;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_SUCCESSFULLY:
        this.orderDetail = action.order;
        this.requestResult.httpCode.orderSynchronization = action.httpCode;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_UNSUCCESSFULLY:
        this.requestResult.httpCode.orderSynchronization = action.httpCode;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.CREATE_IMAGEMAGIC_ORDER:
        this.requestResult.httpCode.createImageMagicOrder = action.httpCode;
        this.requestResult.errorCodes.createImageMagicOrder = action.errorCode;
        if (action.httpCode === 200) {
          this.requestResult.data.createImageMagicOrder = {};
          this.orderDetail = action.data
        } else if (action.errorCode ===
          OrderConstant.EXCEPTIONS.ORDER_IS_CREATING_EXCEPTION) {
          this.requestResult.data.createImageMagicOrder = {};
          this.orderDetail = action.data
        } else {
          this.requestResult.data.createImageMagicOrder = action.data
        }
        switch (action.httpCode) {
          case 200:
            this.orderDetail = action.data.order;
            this.requestResult.data.createImageMagicOrder = {};
            break;
          case 403:
            this.orderDetail = action.data.order;
            this.requestResult.data.createImageMagicOrder = action.data;
            break;
          default:
            this.requestResult.data.createImageMagicOrder = action.data;
            break
        }
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.VERIFY_ORDER_PAYMENT:
        this.requestResult.httpCode.verifyOrderPayment = action.httpCode;
        this.requestResult.data.verifyOrderPayment = {
          paymentStatus: action.paymentStatus,
        };
        this.emitChange();
        break;
      case OrderConstant.BAD_REQUEST:
        this.errorMessage = OrderConstant.ALERT.ERROR;
        this.successMessage = null;
        this.waringMessage = null;
        this.infoMessage = null;
        this.loading = false;
        this.emitChange();
        break;
      case OrderConstant.GATE_WAY_TIME_OUT:
        this.infoMessage = action.message;
        this.successMessage = null;
        this.waringMessage = null;
        this.errorMessage = null;
        this.loading = false;
        this.emitChange();
        break;
      case OrderConstant.ERROR:
        this.errorMessage = OrderConstant.ALERT.ERROR;
        this.successMessage = null;
        this.waringMessage = null;
        this.infoMessage = null;
        this.loading = false;
        this.emitChange();
        break;
      default:
        break;
    }
  }

  setChangeStatusCodeAndMessageForEc(httpCodeForEc, messageForEc) {
    if (this.orderDetail.messages) {
      this.orderDetail.messages.changeStatusMessageForEc = messageForEc
    } else {
      this.orderDetail.messages = {
        changeStatusMessageForEc: messageForEc,
      }
    }
    if (this.orderDetail.httpCode) {
      this.orderDetail.httpCode.changeStatusCodeForEc = httpCodeForEc
    } else {
      this.orderDetail.httpCode = {
        changeStatusCodeForEc: httpCodeForEc,
      }
    }
  }

  deleteDesign(designs) {
    let that = this;
    Array.from(designs).forEach(function (design) {
      let index = that.orderDetail.designs.indexOf(design);
      if (index !== -1) that.orderDetail.designs.splice(index, 1)
    })
  }

  getOrdersDTO() {
    return this.ordersDTO
  }

  getOrderFilter() {
    return this.orderFilter
  }

  getNotChangeStatusWhenExportFlag() {
    return this.notChangeStatusWhenExportFlag
  }

  getSelectedOrders() {
    return this.selectedOrders
  }

  resetOrderFilter() {
    this.orderFilter = {
      currentPage: 1,
      pageSize: 10,
      status: OrderConstant.STATUS.NEW,
      keyword: '',
      orderByExShipDate: 'ASC',
      orderByDefaultDeliDate: null,
    }
  }

  getOrderDetail() {
    return this.orderDetail
  }

  getViewDetail() {
    return this.viewDetail
  }

  getCreatingImageMagicOrderResult() {
    let result = {
      httpCode: this.requestResult.httpCode.createImageMagicOrder,
      errorCode: this.requestResult.errorCodes.createImageMagicOrder,
      data: this.requestResult.data.createImageMagicOrder,
    };
    this.resetCreatingImageMagicOrderResult();
    return result
  }

  resetCreatingImageMagicOrderResult() {
    this.requestResult.httpCode.createImageMagicOrder = null;
    this.requestResult.errorCodes.createImageMagicOrder = null;
    this.requestResult.data.createImageMagicOrder = null
  }

  getOrderStatusChangeResult() {
    let result = {
      httpCode: this.requestResult.httpCode.orderStatusChange,
      message: this.requestResult.messages.orderStatusChange,
    };
    this.resetOrderStatusChangeResult();
    return result
  }

  getShipmentChangeResult() {

    let result = {
      httpCode: this.requestResult.httpCode.shipmentInfoChange,
      message: this.requestResult.messages.shipmentInfoChange,
    };
    this.resetShipmentChangeResult();
    return result
  }

  resetShipmentChangeResult() {
    this.requestResult.httpCode.shipmentInfoChange = null;
    this.requestResult.messages.shipmentInfoChange = null
  }

  resetOrderStatusChangeResult() {
    this.requestResult.httpCode.orderStatusChange = null;
    this.requestResult.messages.orderStatusChange = null
  }

  getSynchronizingOrderResult() {
    let result = {
      httpCode: this.requestResult.httpCode.orderSynchronization,
    };
    this.requestResult.httpCode.orderSynchronization = null;
    return result
  }

  getOrderPaymentResult() {
    let result = {
      httpCode: this.requestResult.httpCode.verifyOrderPayment,
      paymentStatus: this.requestResult.data.verifyOrderPayment.paymentStatus,
    };
    this.resetOrderPaymentResult();
    return result
  }

  resetOrderPaymentResult() {
    this.requestResult.httpCode.verifyOrderPayment = null;
    this.requestResult.data.verifyOrderPayment = {}
  }

  getErrorMessage() {
    return this.errorMessage
  }

  getSuccessMessage() {
    return this.successMessage
  }

  getWaringMessage() {
    return this.waringMessage
  }

  getInfoMessage() {
    return this.infoMessage
  }

  getOrderProcessData() {
    return this.orderProcessData
  }

  isLoading() {
    return this.loading
  }

  getOrderInterval() {
    return this.orderInterval
  }

  clearInterval() {
    clearInterval(this.orderInterval)
  }
}

const orderStore = new OrderStore();

export default orderStore
