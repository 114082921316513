/**
 * Created by thangkc on 14/12/2015.
 */
import React from "react";
import { Link } from "react-router";
import LoginStore from "../common/stores/AuthenticationStore.jsx";
import LoginService from "../common/services/AuthenticationService.jsx";
import NavItemComponent from "../common/components/NavItemComponent.jsx";

export default class MainComponent extends React.Component {
	constructor() {
		super();
		this.state = this._getState();
		this._onChange = this._onChange.bind(this);
	}

	_getState() {
		return {
			userLoggedIn: LoginStore.loggedInUser(),
			route: window.location.pathname,
		};
	}

	_onChange() {
		this.setState(this._getState());
	}

	componentDidMount() {
		LoginStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		LoginStore.removeChangeListener(this._onChange);
	}

	extractCompanyName(accountId) {
		if (accountId.toLowerCase().includes("maw")) {
			return "MAW";
		} else {
			return "MyClo";
		}
	}

	render() {
		const currentAccountId = this.state.userLoggedIn
			? this.state.userLoggedIn.accountId
			: null;
		return (
			<div id="main">
				<div id="header">
					<div className="container">
						<div className="container-fluid">
							<h1 className="pull-left" id="logo">
								<Link to="/">
									TowaStela{" "}
									{currentAccountId
										? `- ${this.extractCompanyName(currentAccountId)}`
										: ""}
								</Link>
							</h1>
							{this.headerItems}
						</div>
					</div>
					{this.navBar}
				</div>
				<div className="container" id="contents">
					{this.props.children}
				</div>
			</div>
		);
	}

	get headerItems() {
		if (this.state.userLoggedIn) {
			return (
				<div className="pull-right" id="top-right">
					<p className="user">
						<span className="user-name">
							<b className="ng-binding">{this.state.userLoggedIn.name} 様</b>
						</span>
						<button
							className="logout text-danger"
							type="button"
							onClick={this.logout.bind(this)}
						>
							<i className="fa fa-power-off" />
						</button>
					</p>
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	get navBar() {
		if (
			this.state.userLoggedIn &&
			!this.state.userLoggedIn.accountId.toLowerCase().includes("maw")
		) {
			return (
				<div className="navbar">
					<div className="container">
						<ul className="nav navbar-nav">
							<NavItemComponent to="/orders">注文一覧</NavItemComponent>
							<NavItemComponent to="/report">レポート</NavItemComponent>
						</ul>
					</div>
				</div>
			);
		} else return <div></div>;
	}

	logout(e) {
		e.preventDefault();
		LoginService.logout();
	}
}
