/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react'
import OrderStatus from '../../OrderConstant.jsx'
import Button from 'react-bootstrap/lib/Button'
import NumberFormatComponent from '../../../common/components/NumberFormatComponent.jsx'

export default class ProductInfoComponent extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onChange = this._onChange.bind(this)
  }

  _onChange () {
    this.setState(this._getState())
  }

  _getState () {
    return {
      products: {},
    }
  }

  render () {
    var products = this.props.products
    return (
      <div>
        <div className="clearfix">
          <h4 className="title pull-left">商品情報</h4>
        </div>
        <table className="table table-bordered">
          <thead>
          <tr>
            <th>商品名</th>
            <th>素材</th>
            <th>時計の形状</th>
            <th>時計のサイズ</th>
            <th>ムーブメント</th>
            <th>時分針カラー</th>
            <th>秒針カラー</th>
            <th>時計のオプション</th>
            <th>数量</th>
          </tr>
          </thead>
          <thead>
          {products.sort(function (a, b) {
            return a.productId > b.productId
          }).map(function (product) {
            let myclo = product.product && product.product.myclo ?
              product.product.myclo :
              {
                productMaterial: '',
                productShape: '',
                productSize: '',
                productMovement: '',
                productHourMinuteHandColor: '',
                productSecondHandColor: '',
                productOption1: '',
                productOption2: '',
              }

            return (
              <tr key={product.orderProductId}>
                <td>{product.productName}</td>
                <td>{myclo.productMaterial}</td>
                <td>{myclo.productShape}</td>
                <td>{myclo.productSize}</td>
                <td>{myclo.productMovement}</td>
                <td>{myclo.productHourMinuteHandColor}</td>
                <td>{myclo.productSecondHandColor}</td>
                <td>{myclo.productOption1} <br/> {myclo.productOption2}</td>
                <td>{product.unit}</td>
              </tr>
            )
          })}

          </thead>
        </table>
      </div>)
  }
}