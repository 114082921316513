import Dispatcher from '../base/dispatcher/Dispatcher.jsx'
import ReportConstant from "./ReportConstant.jsx";

export default {
  load: () => {
    Dispatcher.dispatch({
      actionType: ReportConstant.LOAD,
    })
  },
  showProductReport: (products, listTitle) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT,
      products: products,
      listTitle: listTitle,
    })
  },
  error: (message) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ERROR,
      message: message,
    })
  },
}
