/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react'
import ImageLoader from 'react-imageloader'

export default class DesignImageFormat extends React.Component {
  constructor () {
    super()
  }

  createImageUrl (rowData) {
    return "/api/image/" + rowData.orderNumber + "/" + rowData.imageFolder +  "/" + rowData.thumbnailName;
  }

  createDeletedInfo (rowData) {
    if (rowData.product.isDeleted || rowData.product.status === '1')
      return (
        <span className="product-deleted-notification">本商品は販売を終了いたしました</span>)
    else return ''
  }

  createOutOfStockInfo (rowData) {
    if (rowData.product.quantityInStock === 0)
      return (<span className="info-notification">在庫切れ</span>)
    else return ''
  }

  preloader () {
    return <div className=" default-image-box">
      <span className="loading-spin-icon"/></div>
  }

  createSideIcon (rowData) {
    if (rowData.product.numberOfSides === 1) return ''

    if (rowData.sideNumber === 1) {
      return <span className="front-design">表</span>
    } else {
      return <span className="back-design">裏</span>
    }
  }

  render () {
    return (
      <div className={this.props.className}>
        <ImageLoader
          src={this.createImageUrl(this.props.rowData)}
          wrapper={React.DOM.div}
          preloader={this.preloader}>
          Image load failed!
        </ImageLoader>
        {this.createSideIcon(this.props.rowData)}
        {this.createDeletedInfo(this.props.rowData)}
        {this.createOutOfStockInfo(this.props.rowData)}
      </div>
    )
  }
}