import React from 'react'
import request from 'reqwest'
import when from 'when'
import ReportAction from "./ReportAction.jsx";
import ReportConstant from "./ReportConstant.jsx";
import CommonService from "./../common/services/CommonService.jsx"
import LoginAction from "../common/actions/AuthenticationAction.jsx";

class ReportService {

  getProductReport (reportType, DateType ,fromDate, toDate) {
    var params = ['reportType=' + reportType, 'filterDate=' + DateType]
    var dateFormatter = '{0}/{1}/{2}'
    if (!!fromDate) {
      params.push('fromDate=' + encodeURIComponent(
        dateFormatter.format(fromDate.date(), fromDate.month() +
          1, fromDate.year())))
    }
    if (!!toDate) {
      params.push('toDate=' + encodeURIComponent(
        dateFormatter.format(toDate.date(), toDate.month() + 1,
          toDate.year())))
    }
    var url = ReportConstant.API.PRODUCT_REPORT_URL
    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          let groupName = response.data.products.groupBy('name');
          Object.keys(groupName).forEach(function(key) {
            groupName[key] = groupName[key].groupBy(reportType === 'DAY' ? 'ymd': 'ym')
          });
          let listTitle = CommonService.createReportTitle(fromDate, toDate, reportType);
          ReportAction.showProductReport(groupName, listTitle)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }
}

export default new ReportService()
