/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react'
import OrderConstant from '../../OrderConstant.jsx'
import OrderService from '../../OrderService.jsx'
import DatePicker from 'react-datepicker'
import ja from 'moment/locale/ja.js'
import moment from 'moment'
import FlashComponent from '../../../common/components/FlashComponent.jsx'
import LoadingButton from '../../../common/components/LoadingButtonComponent.jsx'
import ConfirmDialog from '../../../common/components/dialogs/ConfirmDialog.jsx'

export default class ShipInfoComponent extends React.Component {
  constructor () {
    super()
    this._getState      = this._getState.bind(this)
    this._onChange      = this._onChange.bind(this)
    this._onDateChange  = this._onDateChange.bind(this)
    this.changeShipDate = this.changeShipDate.bind(this)
    this.changeShipId   = this.changeShipId.bind(this)
    this.save           = this.save.bind(this)
    this.renderUpdateShipmentMessage = this.renderUpdateShipmentMessage.bind(this)
    this.resetShipmentChangeResult   = this.resetShipmentChangeResult.bind(this)
    this.closeChangeShipmentInfoDialog = this.closeChangeShipmentInfoDialog.bind(this)
    this.openChangeShipmentInfoDialog = this.openChangeShipmentInfoDialog.bind(this)
    this.state = {
      shipId: null,
      shipDate: null,
      shipmentChangeResult: {},
      isLoading: false,
      openChangeShipmentInfoDialogFlg: false
    }
    moment.locale('ja', { months : '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_')});

  }

  _onChange () {
    this.setState(this._getState(this.props))
  }

  _getState(props) {
    if (!!props.order) {
      return {
        shipId: props.order.shipId,
        shipDate: props.order.shipDate ? moment(props.order.shipDate) : null,
        shipmentChangeResult: props.shipmentChangeResult,
        isLoading: props.isLoading
      }
    } else {
      return {
        shipId: '',
        shipDate: null,
        shipmentChangeResult: {
          httpCode: null,
          message: null
        },
        isLoading: false
      }
    }
  }

  _onDateChange (startDate) {
    this.setState({shipDate: startDate})
  }


  componentDidMount () {
    this._onChange()
  }

  componentWillReceiveProps (nextProps) {
    this.setState(this._getState(nextProps))
  }

  componentWillUnmount () {
  }

  changeShipId (event) {
    this.setState({
      shipId: event.target.value,
    })
  }

  changeShipDate (event) {
    this.setState({
      shipDate: event.target.value
    })
  }

  save() {
    this.setState({isLoading: true})
    OrderService.changeOrderShipment(this.props.order.randomId, this.state.shipId, this.state.shipDate.format("YYYY-MM-DD"))
  }

  resetShipmentChangeResult () {
    this.setState({
      shipmentChangeResult: {},
    })
  }

  openChangeShipmentInfoDialog () {
    this.setState({
      openChangeShipmentInfoDialogFlg: true,
    })
  }

  closeChangeShipmentInfoDialog () {
    this.setState({
      openChangeShipmentInfoDialogFlg: false,
    })
  }


  renderUpdateShipmentMessage () {
    if (this.state.shipmentChangeResult.httpCode) {
      let result = null
      switch (this.state.shipmentChangeResult.httpCode) {
        case 200:
          result = {
            message: this.state.shipmentChangeResult.message,
            className: 'text-success',
          }
          break
        default:
          result = {
            message: this.state.shipmentChangeResult.message,
            className: 'text-danger',
          }
          break
      }
      return <FlashComponent visible={true} timeout={5000}
                             className={result.className}
                             onHide={this.resetShipmentChangeResult}>
        {result.message}</FlashComponent>
    } else {
      return null
    }
  }

  isDisabledByPrintStatus(status){
    return status === OrderConstant.STATUS.SHIPPED || status === OrderConstant.STATUS.CANCEL
  }

  render () {
    let order = !!this.props.order ? this.props.order : {
      randomId: '',
      printStatus: '',
      expectedShipDate: '',
      deliveryDays: '',
      deliveryTime: ''
    }
    return (
      <div className="col-md-6">
        <div className="clearfix">
          <h4 className="title pull-left">出荷情報</h4>
          <div className="clearfix pull-right">
            {this.renderUpdateShipmentMessage()}
            <LoadingButton isLoading={this.state.isLoading}
                           className="btn-raised btn btn-info btn-lg"
                           onClick={this.openChangeShipmentInfoDialog}
                           disabled={!(this.state && this.state.shipId && this.state.shipDate) || this.isDisabledByPrintStatus(order.printStatus)}>
              保存
            </LoadingButton>
            <ConfirmDialog showModal={this.state.openChangeShipmentInfoDialogFlg}
                           accept={this.save}
                           close={this.closeChangeShipmentInfoDialog}
                           acceptTitle="送信する"
                           rejectTitle="キャンセル"
                           message="お客様に配送メールを送信しますか？"/>
          </div>
        </div>
        <table className="table table-bordered shipment-info-table">
          <thead>
          <tr>
            <th colSpan="2">出荷情報</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th width="190px">連携オーダーID</th>
            <td>{order.randomId}</td>
          </tr>
          <tr>
            <th>配送 No.</th>
            <td>
              <input className="form-control"
                     value={this.state ? this.state.shipId : ""}
                     onChange={this.changeShipId}
                     disabled={this.isDisabledByPrintStatus(order.printStatus)}/>
            </td>
          </tr>
          <tr>
            <th>出荷予定日</th>
            <td>{order.expectedShipDate ? order.expectedShipDate : '-'}</td>
          </tr>
          <tr>
            <th>出荷日</th>
            <td className="date-picker">
              <DatePicker className="form-control"
                          dateFormat={"YYYY/MM/DD"}
                          minDate={moment().subtract(15, 'days')}
                          maxDate={moment()}
                          selected={this.state && this.state.shipDate
                            ? this.state.shipDate
                            : null}
                          placeholderText="出荷日を選択してください。"
                          onChange={this._onDateChange}
                          locale="ja"
                          readOnly={true}
                          todayButton={"今日"}
                          isClearable={!this.isDisabledByPrintStatus(order.printStatus)}
                          disabled={this.isDisabledByPrintStatus(order.printStatus)}/>
            </td>
          </tr>
          {/*<tr>*/}
          {/*  <th>到着予定日</th>*/}
          {/*  <td>{order.defaultExpectedDeliveryDate}</td>*/}
          {/*</tr>*/}
          <tr>
            <th>到着希望日</th>
            <td>{order.deliveryDays}</td>
          </tr>
          <tr>
            <th>到着希望時間帯</th>
            <td>{order.deliveryTime}</td>
          </tr>
          </tbody>
        </table>
      </div>)
  }
}
