import React from "react";

import AuthenticatedComponent from "../common/components/AuthenticatedComponent.jsx";
import LoginStore from "../common/stores/AuthenticationStore.jsx";
import MyCloOrderListComponent from "./component/OrderListComponent.jsx";
import MawOrderListComponent from "../maw/order/component/MawOrderListComponent.jsx";

export default AuthenticatedComponent(
	class OrderPage extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				userLoggedIn: LoginStore.loggedInUser(),
			};
		}

		render() {
			let orderPage;

			// hiện tại chỉ có MAW và Myclo, nên có thể sử dụng if else như dưới
			if (this.state.userLoggedIn.accountId.toLowerCase().includes("maw")) {
				orderPage = <MawOrderListComponent />;
			} else {
				orderPage = <MyCloOrderListComponent />;
			}

			return orderPage;
		}
	}
);
