/**
 * Created by thangkc on 14/12/2015.
 */

const DesignConstant = {
  API: {
    URL: '/api/design',
  },
  ACTION_TYPE: {
    DELETE: 'DELETE',
    LOAD: 'LOAD',
    CHANGE_KEYWORD: 'CHANGE_KEYWORD',
    CHANGE_STT_FIL: 'CHANGE_STT_FIL',
    CHANGE_PAGE: 'CHANGE_PAGE',
  },
  STATUS: {
    ALL: 'ALL',
    WRONG_PRODUCT: 'WRONG_PRODUCT',
    DRAFT: 'DRAFT',
    CREATING: 'CREATING',
    SHIPPED: 'SHIPPED',
    COMMITTED: 'COMMITTED',
    PENDING: 'PENDING',
    PAUSE: 'PAUSE',
    CANCEL: 'CANCEL',
  },
  STATUS_JP: {
    ALL: '全て',
    WRONG_PRODUCT: '商品カテゴリ変更確認',
    DRAFT: '下書き',
    CREATING: '制作中',
    SHIPPED: '出荷済み',
    COMMITTED: '確定済み',
    PENDING: '保留',
    PAUSE: '制作停止',
    CANCEL: 'キャンセル',
  },
}

export default DesignConstant
