import React from "react";

import AuthenticatedComponent from "../common/components/AuthenticatedComponent.jsx";

import LoginStore from "../common/stores/AuthenticationStore.jsx";

import ProductReportComponent from "./component/product/ProductReportComponent.jsx";

export default AuthenticatedComponent(
	class ReportPage extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				userLoggedIn: LoginStore.loggedInUser(),
			};
		}
		render() {
			let reportPage;

			// hiện tại chỉ có MAW và Myclo, nên có thể sử dụng if else như dưới
			if (this.state.userLoggedIn.accountId.toLowerCase().includes("maw")) {
				reportPage = <div></div>;
			} else {
				reportPage = <ProductReportComponent />;
			}
      
			return reportPage;
		}
	}
);
