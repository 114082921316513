import Dispatcher from '../base/dispatcher/Dispatcher.jsx'
import OrderConstant from './OrderConstant.jsx'

export default {
  load: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.LOAD,
    })
  },
  loadedOrders: (ordersDTO, orderFilter) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.LOADED_ORDERS,
      ordersDTO: ordersDTO,
      orderFilter: orderFilter,
    })
  },
  detail: (order) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.DETAIL,
      order: order,
    })
  },
  showOrderDetail: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SHOW_ORDER_DETAIL,
    })
  },
  hideOrderDetail: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.HIDE_ORDER_DETAIL,
    })
  },
  changeKeyword: (keyword) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_KEYWORD,
      keyword: keyword,
    })
  },
  changeStatus: (status) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_SEARCH_STATUS,
      status: status,
    })
  },
  changeStatusWhenExportCSV: (status) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS_WHEN_EXPORT_CSV,
      status: status,
    })
  },
  changePage: (currentPage) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_SEARCH_PAGE,
      currentPage: currentPage,
    })
  },
  changeOrderSortType: (shipDateType, deliDateType) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_SORT_TYPE,
      orderByExShipDate: shipDateType,
      orderByDefaultDeliDate: deliDateType
    })
  },
  changeShipmentInfo: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_SHIPMENT_INFO,
      order: data,
      message: message,
      httpCode: httpCode,
    })
  },
  changeOrderStatus: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS,
      order: data,
      message: message,
      httpCode: httpCode,
    })
  },
  changeEcOrderStatus: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_EC_STATUS,
      orderForEc: data,
      messageForEc: message,
      httpCodeForEc: httpCode,
    })
  },
  rollbackChangeOrderStatus: (httpCode, message, errorCode) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_ROLLBACK_CHANGE_STATUS,
      message: message,
      errorCode: errorCode,
      httpCode: httpCode,
    })
  },
  synchronizeOrder: (order) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_SUCCESSFULLY,
      order: order,
      httpCode: 200,
    })
  },
  rollbackOrderSynchronization: (httpCode) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_UNSUCCESSFULLY,
      httpCode: httpCode,
    })
  },
  createImageMagicOrder: (httpCode, errorCode, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.CREATE_IMAGEMAGIC_ORDER,
      httpCode: httpCode,
      errorCode: errorCode,
      data: data,
    })
  },
  verifyOrderPayment: (httpCode, paymentStatus) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.VERIFY_ORDER_PAYMENT,
      httpCode: httpCode,
      paymentStatus: paymentStatus,
    })
  },
  selectOrder: (orderNumbers) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SELECT_ORDER,
      orderNumbers: orderNumbers,
    })
  },
  removeSelectedOrder: (orderNumbers) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.REMOVE_SELECTED_ORDER,
      orderNumbers: orderNumbers,
    })
  },
  uploadCsvSuccess: (mycloShippedFileId, orderProcessData) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.UPLOAD_ORDERS_CSV,
      mycloShippedFileId: mycloShippedFileId,
      orderProcessData: orderProcessData
    })
  },
  getShipmentProcessNumber: (data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.GET_ORDERS_PROCESS_DATA,
      data: data
    })
  },
  badRequest: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.BAD_REQUEST,
    })
  },
  gateWayTimeOut: (message) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.GATE_WAY_TIME_OUT,
      message: message,
    })
  },
  error: (message) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ERROR,
      message: message,
    })
  },
}