/**
 * Created by hoangnv on 1/9/17.
 */
import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class FlashComponent extends React.Component {

  constructor (props) {
    super()
    let self = this
    this.fadeOutTimer = {
      timeout: props.timeout,
      timer: null,
      start: function () {
        this.stop()
        this.timer = setTimeout(function () {
          self.hide()
        }, this.timeout)
      },
      stop: function () {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      },
    }
    this.fadeOutTimer.start.bind(this.fadeOutTimer)
    this.fadeOutTimer.stop.bind(this.fadeOutTimer)
    this.state = this.getState(props)
  }

  getState (props) {
    return {
      visible: props.visible,
    }
  }

  hide () {
    this.setState({
      visible: false,
    })
    if (this.props.onHide) {
      this.props.onHide()
    }
  }

  componentDidMount () {
    if (this.state.visible) {
      this.fadeOutTimer.start()
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState(this.getState(nextProps))
    if (this.state.visible) {
      this.fadeOutTimer.start()
    }
  }

  componentWillUnmount () {
    this.fadeOutTimer.stop()
  }

  render () {
    return (this.state.visible ?
      <ReactCSSTransitionGroup transitionName="fadeOut"
                               transitionEnterTimeout={500}
                               transitionLeaveTimeout={300}>
        <span className={this.props.className}>{this.props.children}</span>
      </ReactCSSTransitionGroup> : null)
  }
}
