/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react'
import OrderStore from '../../OrderStore.jsx'
import StatusComponent from './status/StatusComponent.jsx'
import OrdererComponent from './OrdererComponent.jsx'
import ReceiverComponent from './ReceiverComponent.jsx'
import ProductInfoComponent from './ProductInfoComponent.jsx'
import DesignInfoComponent from './DesignInfoComponent.jsx'
import ShipInfoComponent from './ShipInfoComponent.jsx'
import OrderService from '../../OrderService.jsx'
import BillComponent from './BillComponent.jsx'
import Modal from 'react-bootstrap/lib/Modal'
import Button from 'react-bootstrap/lib/Button'

export default class OrderDetailComponent extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onChange = this._onChange.bind(this)
  }

  _onChange () {
    this.setState(this._getState())
  }

  _getState () {
    return {
      order: OrderStore.getOrderDetail(),
      showModal: OrderStore.getViewDetail(),
      shipmentChangeResult: OrderStore.getShipmentChangeResult()
    }
  }

  componentDidMount () {
    OrderStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    OrderStore.removeChangeListener(this._onChange)
  }

  close () {
    OrderService.hideOrderDetail()
  }

  render () {
    return (
      <Modal show={this.state.showModal} onHide={this.close.bind(this)}
             bsSize="large" className="order-setting"
             dialogClassName="order-detail">
        <Modal.Header closeButton>
          <h2 className="modal-title">詳細注文情報</h2>
        </Modal.Header>
        <Modal.Body>
          <StatusComponent order={this.state.order.packages[0]}/>
          <div className="row">
            <OrdererComponent orderer={this.state.order.orderer}
                              payMethod={this.state.order.payMethod}/>
            <ReceiverComponent receiver={this.state.order.receiver}/>
          </div>
          <DesignInfoComponent order={this.state.order}/>
          <BillComponent order={this.state.order}/>
          <hr className="line"/>
          <div className="row">
            <ShipInfoComponent order={this.state.order.packages[0]}
                               shipmentChangeResult={this.state.shipmentChangeResult}
                               isLoading={false}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close.bind(this)}
                  className="btn-raised">閉じる</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}