/**
 * Created by thangkc on 14/12/2015.
 */

const AuthenticationConstant = {
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
  URL: '/api/authenticate',
}

export default AuthenticationConstant
