import React from "react";
import AuthenticatedComponent from "../../../common/components/AuthenticatedComponent.jsx";
import SearchBoxComponent from "../../../common/components/SearchBoxComponent.jsx";
import OurPagingComponent from "../../../common/components/paging/OurPagingComponent.jsx";
import MawOrderStatusBarComponent from "./MawOrderStatusBarComponent.jsx";
import MawOrderTableComponent from "./MawOrderTableComponent.jsx";
import OrderDetailComponent from "./detail/OrderDetailComponent.jsx";
import OrderService from "../OrderService.jsx";
import OrderAction from "../OrderAction.jsx";
import OrderStore from "../OrderStore.jsx";
import { Alert } from "react-bootstrap";
import LoadingButton from "../../../common/components/LoadingButtonComponent.jsx";

export default AuthenticatedComponent(
	class MawOrderListComponent extends React.Component {
		constructor() {
			super();
			this.state = this._getInitialState();
			this._onChange = this._onChange.bind(this);
			this.closeExportOrderReportDialog =
				this.closeExportOrderReportDialog.bind(this);
			this.openExportOrderReportDialog =
				this.openExportOrderReportDialog.bind(this);
			this.onChangeHandlerFileUpload =
				this.onChangeHandlerFileUpload.bind(this);
			this.handleUploadFileShipped = this.handleUploadFileShipped.bind(this);
			this.closeMessage = this.closeMessage.bind(this);
		}

		_onChange() {
			this.setState(this._getState());
		}

		_getInitialState() {
			OrderStore.resetOrderFilter();
			OrderStore.resetMessage();
			OrderStore.getOrderProcessData();
			OrderStore.isLoading();
			return this._getState();
		}

		_getState() {
			return {
				ordersDTO: OrderStore.getOrdersDTO(),
				orderFilter: OrderStore.getOrderFilter(),
				selectedOrders: OrderStore.getSelectedOrders(),
				notChangeStatusWhenExportFlg:
					OrderStore.getNotChangeStatusWhenExportFlag(),
				isOpenExportOrderReportDialog: false,
				selectedFile: undefined,
				successMessage: OrderStore.getSuccessMessage(),
				waringMessage: OrderStore.getWaringMessage(),
				infoMessage: OrderStore.getInfoMessage(),
				errorMessage: OrderStore.getErrorMessage(),
				orderProcessData: OrderStore.getOrderProcessData(),
				loading: OrderStore.isLoading(),
			};
		}

		componentDidMount() {
			OrderService.loadOrders(OrderStore.getOrderFilter());
			OrderStore.addChangeListener(this._onChange);
		}

		componentWillUnmount() {
			OrderStore.removeChangeListener(this._onChange);
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (
				this.state.orderProcessData &&
				this.state.orderProcessData.processing === 0
			) {
				OrderStore.clearInterval();
			}
		}

		openExportOrderReportDialog() {
			this.setState({
				isOpenExportOrderReportDialog: true,
			});
		}

		closeExportOrderReportDialog() {
			this.setState({
				isOpenExportOrderReportDialog: false,
			});
		}

		onChangeHandlerFileUpload(event) {
			event.persist();
			const file = event.target.files[0];
			if (file !== undefined) {
				this.setState(() => ({
					selectedFile: file,
				}));
			} else {
				this.setState(() => ({ selectedFile: undefined }));
			}
		}

		handleUploadFileShipped(event) {
			if (event) event.preventDefault();
			this.setState(
				() => ({
					orderProcessData: null,
				}),
				() => {
					OrderService.uploadShippedCSV(
						this.state.selectedFile,
						this.state.orderProcessData
					);
					this.myFormRef.reset();
				}
			);
		}

		closeMessage(event) {
			event.persist();
			this.setState(() => ({
				loading: false,
				selectedFile: undefined,
				errorMessage: null,
				successMessage: null,
				waringMessage: null,
				infoMessage: null,
			}));
		}

		render() {
			return (
				<div>
					{this.state.successMessage ? (
						<Alert bsStyle="success">
							{this.state.successMessage}
							<button className="close" onClick={this.closeMessage}>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
						</Alert>
					) : (
						""
					)}
					{this.state.waringMessage ? (
						<Alert bsStyle="warning">
							{this.state.waringMessage}
							<button className="close" onClick={this.closeMessage}>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
						</Alert>
					) : (
						""
					)}
					{this.state.infoMessage ? (
						<Alert bsStyle="info">
							{this.state.infoMessage}
							<button className="close" onClick={this.closeMessage}>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
						</Alert>
					) : (
						""
					)}
					{this.state.errorMessage ? (
						<Alert bsStyle="danger">
							{this.state.errorMessage}
							<button className="close" onClick={this.closeMessage}>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
						</Alert>
					) : (
						""
					)}
					<form encType="multipart/form-data" ref={(el) => this.myFormRef = el}>
						<div className="form-group form-make-shop-upload margin-top-10">
							<label className="label-make-shop-upload-csv"
								   htmlFor="exampleFormControlFile2">一括「配送情報」取込み</label>
							<div className="container-fluid">
								<div className="row wrap-form-csv">
									<div className="col-md-10 block-file-make-shop">
										<input type="file"
											   className="form-control"
											   name="shippedCsv"
											   id="exampleFormControlFile2"
											   onChange={this.onChangeHandlerFileUpload}
											   accept=".csv" required/>
									</div>
									<div className="col-md-2">
										<LoadingButton
											disabled={this.state.selectedFile === undefined}
											isLoading={this.state.loading}
											className="btn-raised btn btn-info btn-md"
											onClick={this.handleUploadFileShipped}>アップロード</LoadingButton>
									</div>
								</div>
							</div>
						</div>
					</form>
					<SearchBoxComponent
						changeKeyword={OrderAction.changeKeyword}
						placeholder="オーダーIDで検索"
					/>
					{/*<div>*/}
					{/*	{this.state.orderProcessData ? (*/}
					{/*		<p className="titleFilterUpdateStatus">*/}
					{/*			処理済み件数:&nbsp;*/}
					{/*			<span className="text-warning">*/}
					{/*				{this.state.orderProcessData.all -*/}
					{/*					this.state.orderProcessData.processing}*/}
					{/*				/{this.state.orderProcessData.all}件*/}
					{/*			</span>*/}
					{/*		</p>*/}
					{/*	) : (*/}
					{/*		""*/}
					{/*	)}*/}
					{/*</div>*/}
					<MawOrderStatusBarComponent
						ordersDTO={this.state.ordersDTO}
						orderFilter={this.state.orderFilter}
						isChecked={this.state.notChangeStatusWhenExportFlg}
						selectedOrders={this.state.selectedOrders}
					/>
					<MawOrderTableComponent
						orders={this.state.ordersDTO.orders}
						orderFilter={this.state.orderFilter}
						selectedOrders={this.state.selectedOrders}
						orderStatus={this.state.orderFilter.status}
					/>
					<OurPagingComponent
						loadNewPage={OrderAction.changePage}
						dto={this.state.ordersDTO}
					/>
					<OrderDetailComponent />
				</div>
			);
		}
	}
);
