import React from 'react'

class ProductReportTable extends React.Component {

  constructor () {
    super()
  }

  render () {
    let listTitle = this.props.listTitle
    let products = this.props.products
    let total = 0
    Object.keys(products).map(function (productName) {
      let productData = products[productName]
      let rowTotal = 0
      Object.keys(listTitle).map(function (key) {
        let product = productData[key]
        let quantity = 0
        if (product && product[0]['quantity']) {
          quantity = product[0]['quantity']
          total = total + quantity
          rowTotal = rowTotal + quantity
        }
      })
      products[productName].total = rowTotal
    })
    return (
      <div className="product-report">
        <div className="left">
          <table className="table table-striped table-hover table-bordered"
                 width="200px">
            <thead>
            <tr>
              <th className="name-col">商品名</th>
              <th className="count-col">数量</th>
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(products).map(function (productName) {
                return (
                  <tr key={productName}>
                    <td className="name-col">{productName}</td>
                    <td>{products[productName].total}</td>
                  </tr>
                )
              })
            }

            <tr>
              <td className="name-col sum-title">合計：
              </td>
              <td className="sum-value">{total}</td>
            </tr>

            </tbody>
          </table>
        </div>
        <div className="right">
          <table className="table table-striped table-hover table-bordered">
            <thead>
            <tr>
              <th className="name-col">商品名</th>
              <th className="count-col">数量</th>
              {
                Object.keys(listTitle).map(function (key) {
                  return (
                    <th key={key}>
                      {listTitle[key]}
                    </th>
                  )
                })
              }

            </tr>
            </thead>
            <tbody>
            {
              Object.keys(products).map(function (productName) {
                let productData = products[productName]
                return (
                  <tr key={productName}>
                    <td className="name-col">{productName}</td>
                    <td>{productData.total}</td>
                    {
                      Object.keys(listTitle).map(function (key) {
                        let product = productData[key]
                        let quantity = 0
                        if (product && product[0]['quantity']) {
                          quantity = product[0]['quantity']
                        }
                        return (
                          <td key={key}>
                            {
                              quantity
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }

            <tr>
              <td className="name-col">
              </td>
              <td className="sum-value">{total}</td>
              <td className="sum-title"
                  colSpan={Object.keys(listTitle).length}>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ProductReportTable
