/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react'
import OrderConstant from '../../../order/OrderConstant.jsx'

export default class OrderStatusFormat extends React.Component {
  constructor (props) {
    super()
    this.state = this.getState(props)
  }

  getState (props) {
    return {
      visible: (function () {
        if (props.visible == undefined || props.visible == null) {
          return true
        } else {
          return props.visible
        }
      })(),
    }
  }

  render () {
    var renderOption = this.props.renderOption || false
    if (renderOption) {
      return (<option style={{display: this.state.visible ? '' : 'none'}}
                      value={this.props.data}>{this.statusStr}</option>)
    } else {
      return (<b>{this.statusStr}</b>)
    }
  }

  get statusStr () {
    return OrderConstant.STATUS_JP[this.props.data]
  }
}