const OrderConstant = {
  LOAD: 'LOAD_SHIPPED_MYCLO',
  BAD_REQUEST: 'BAD_REQUEST_INSERT_ORDERS',
  GATE_WAY_TIME_OUT: 'GATE_WAY_TIME_OUT_INSERT_ORDERS',
  ERROR: 'ERROR_INSERT_ORDERS',
  EC_SITE_ID: {
    YAHOO: 'YAHOO',
    RAKUTEN: 'RAKUTEN',
    COLOR_ME: 'COLOR_ME',
  },
  API: {
    GET_ORDER_URL: '/api/orders',
    DETAIL_URL: '/api/order',
    CHANGE_STATUS_URL: '/api/order/{0}/status/{1}',
    EXPORT_SHIPMENT_CSV: '/api/order/shipment/csv',
    CHANGE_SHIPMENT_INFO_URL: '/api/order/{0}/shipment/{1}/{2}',
    SYNCHRONIZE_URL: '/api/order/{0}/synchronization',
    CREATE_IMAGEMAGIC_ORDER: '/api/order/{0}?updatedAt={1}',
    VERIFY_ORDER_PAYMENT: '/api/order/{0}/payment',
    EXPORT_ORDER_REPORT: '/api/export/order-report',
    UPLOAD_FILE_SHIPPED_CSV: '/api/order/shipment/bulkUpdateShipmentByCSV',
    GET_ORDERS_PROCESS: '/api/order/shipment/wait'
  },
  ACTION_TYPE: {
    LOADED_ORDERS: 'LOADED_ORDERS',
    SELECT_ORDER: 'SELECT_ORDER',
    REMOVE_SELECTED_ORDER: 'REMOVE_SELECTED_ORDER',
    DETAIL: 'DETAIL',
    SHOW_ORDER_DETAIL: 'SHOW_ORDER_DETAIL',
    HIDE_ORDER_DETAIL: 'HIDE_ORDER_DETAIL',
    ON_CHANGE_KEYWORD: 'ON_CHANGE_KEYWORD',
    ON_SEARCH_STATUS: 'ON_SEARCH_STATUS',
    ON_CHANGE_STATUS_WHEN_EXPORT_CSV: 'ON_CHANGE_STATUS_WHEN_EXPORT_CSV',
    ON_SEARCH_PAGE: 'ON_SEARCH_PAGE',
    ON_CHANGE_STATUS: 'ON_CHANGE_STATUS',
    ON_CHANGE_SHIPMENT_INFO: 'ON_CHANGE_SHIPMENT_INFO',
    ON_CHANGE_EC_STATUS: 'ON_CHANGE_EC_STATUS',
    ON_ROLLBACK_CHANGE_STATUS: 'ON_ROLLBACK_CHANGE_STATUS',
    ON_CHANGE_SORT_TYPE: 'ON_CHANGE_SORT_TYPE',
    SYNCHRONIZE_ORDER_SUCCESSFULLY: 'SYNCHRONIZE_ORDER_SUCCESSFULLY',
    SYNCHRONIZE_ORDER_UNSUCCESSFULLY: 'SYNCHRONIZE_ORDER_UNSUCCESSFULLY',
    CREATE_IMAGEMAGIC_ORDER: 'CREATE_IMAGEMAGIC_ORDER',
    UPLOAD_ORDERS_CSV: 'UPLOAD_ORDERS_CSV',
    GET_ORDERS_PROCESS_DATA: 'GET_ORDERS_PROCESS_DATA',
  },
  STATUS: {
    ALL: 'ALL',
    NEW: 'NEW',
    CREATING: 'CREATING',
    SHIPPED: 'SHIPPED',
    CANCEL: 'CANCEL',
  },
  STATUS_JP: {
    ALL: '全て',
    NEW: '新規注文',
    CREATING: '制作中',
    SHIPPED: '出荷済み',
    CANCEL: 'キャンセル',
  },
  CREATION_STATE: {
    CHECK_DESIGN: 'CHECK_DESIGN',
    CREATING_DESIGN: 'CREATING_DESIGN',
    CREATING_XML: 'CREATING_XML',
    UPLOADING: 'UPLOADING',
    CHANGING_STATUS: 'CHANGING_STATUS',
    SENDING_EMAIL: 'SENDING_EMAIL',
    DONE: 'DONE',
  },
  PAYMENT_STATUS: {
    SETTLED: 'ORDER_SETTLED',
    NOT_SETTLED: 'ORDER_NOT_SETTLED',
  },
  EXCEPTIONS: {
    ORDER_IS_CREATING_EXCEPTION: 'ORDER_IS_CREATING_EXCEPTION',
    WRONG_PRODUCT_EXCEPTION: 'WRONG_PRODUCT_EXCEPTION',
    NOT_ENOUGH_DESIGN_EXCEPTION: 'NOT_ENOUGH_DESIGN_EXCEPTION',
    SPARE_DESIGNS_EXCEPTION: 'SPARE_DESIGNS_EXCEPTION',
    ORDER_IS_MODIFIED: 'ORDER_IS_MODIFIED',
    EC_UPDATE_ERROR: 'EC_UPDATE_ERROR',
  },
  SHIPPER_ID: {
    SAGAWA: 0,
    KURONEKO: 1,
  },
  ALERT: {
    SUCCESS: 'アップロードが成功しました。出荷情報を処理しています。',
    EXISTED: 'アップロードされた出荷CSVファイルは既に反映されています。',
    ERROR: 'エラーが発生しました'
  }
};

export default OrderConstant
