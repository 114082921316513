/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react'
import ConfirmDialog from '../dialogs/ConfirmDialog.jsx'
require("babel-core/register");
require("babel-polyfill");

export default class DesignActionFormat extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onChange = this._onChange.bind(this)
    this.deleteDesign = this.deleteDesign.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.showConfirmDialog = this.showConfirmDialog.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
  }

  _getState () {
    return {
      showConfirmDialog: false,
      deleteId: null,
    }
  }

  _onChange () {
    this.setState(this._getState())
  }


  async download(mode){

    var fileName = "";
    var url = ""
    switch (mode) {
      case "complete":
        fileName = `${this.props.orderNumber}_${mode}.png`
        url =  this.props.rowData.completedImageUrl
        break;
      case "thumbnail":
        fileName = `${this.props.orderNumber}_${mode}.png`
        url =  this.props.rowData.thumbnailImageUrl
        break
    }
    //todo download file

  }

  downloadImage(mode) {
    let downLoadCompletedImageLink = "/api/design/" + this.props.rowData.orderNumber + "/" + this.props.rowData.groupNumber + `/complete?randomId=${this.props.orderNumber}`;
    let downLoadThumbnailImageLink = "/api/design/" + this.props.rowData.orderNumber + "/" + this.props.rowData.groupNumber + `/thumbnail?randomId=${this.props.orderNumber}`;

    fetch(mode === 'thumbnail' ? downLoadThumbnailImageLink : downLoadCompletedImageLink,
        {
          headers: {
            'Csrf-Token': 'nocheck',
            'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
          },
        })
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = this.props.rowData.randomId + '_'+ mode + '.png';
            a.click();
          });
          //window.location.href = response.url;
        });
  }


  render () {
    return (
      <div>
        {this.confirmBox}
        <span className="design action">
          <button className="btn btn-raised btn-info" onClick={() => this.downloadImage('design')}>
              <i className="fa fa-download"/>加工画像
          </button>
          <button className="btn btn-raised btn-info" onClick={() => this.downloadImage('thumbnail')}>
              <i className="fa fa-download"/>サムネイル画像
          </button>
        </span>
      </div>
    )
  }

  showConfirmDialog () {
    this.setState({
      showConfirmDialog: true,
    })
  }

  closeConfirmDialog () {
    this.setState({
      showConfirmDialog: false,
    })
  }

  deleteDesign () {
    this.setState({viewConfirm: false})
  }

  makeGroupDesignId (designs) {
    let designIds = []
    Array.from(designs).forEach(function (d) {
      designIds.push(d.randomId)
    })

    return designIds.join('-')
  }

  get confirmBox () {
    return <ConfirmDialog showModal={this.state.showConfirmDialog}
                          accept={this.deleteDesign}
                          close={this.closeConfirmDialog}
                          message={this.makeGroupDesignId(this.props.rowData) +
                          'のデザインを削除します。よろしいですか？'}/>

  }

}