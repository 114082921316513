import React from 'react'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import LoadingButton from '../../../common/components/LoadingButtonComponent.jsx'
import ProductReportTable from './ProductReportTable.jsx'
import ReportStore from "../../ReportStore.jsx";
import ReportService from "../../ReportService.jsx";
import ReportAction from "../../ReportAction.jsx";

class ProductReportComponent extends React.Component {
  constructor () {
    super()

    this.dateTypes = [
      {value: 'EX_SHIP_DATE', label: '出荷予定日'},
      {value: 'SHIPPED_DATE', label: '出荷日'},
    ]

    this.reportTypes = [
      {value: 'DAY', label: '日'},
      {value: 'MONTH', label: '月'},
    ]

    this.state = this._getInitialState()
    this._onDateTypeSelect = this._onDateTypeSelect.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this._onReportTypeSelect = this._onReportTypeSelect.bind(this)
    this._onChange = this._onChange.bind(this)

    moment.locale('ja')
  }

  componentDidMount () {
    ReportService.getProductReport(
      this.state.selectedReportType,
      this.state.selectedDateType,
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate),
    )
    ReportStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    ReportStore.removeChangeListener(this._onChange)
  }

  _onChange () {
    this.setState(this._getState())
  }

  _onDateTypeSelect (selectedDateType) {
    this.setState({selectedDateType: selectedDateType.value})
  }

  _onReportTypeSelect (selectedType) {
    this.setState({selectedReportType: selectedType.value})
  }

  _onDateChange ({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange (focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _getInitialState () {
    return {
      selectedDateType: 'SHIPPED_DATE',
      selectedReportType: 'DAY',
      selectedStartDate: moment().subtract(1, 'months'),
      selectedEndDate: moment(),
      focusedInput: null,
      listProductTitle: [],
      products: null,
      errorMessage: null,
      loading: ReportStore.isLoading(),
    }
  }

  _getState () {
    return {
      selectedDateType: this.state.selectedDateType,
      selectedStartDate: this.state.selectedStartDate,
      selectedEndDate: this.state.selectedEndDate,
      focusedInput: this.state.focusedInput,
      listProductTitle: ReportStore.getListProductTitle(),
      products: ReportStore.getProducts(),
      errorMessage: ReportStore.getErrorMessage(),
      loading: ReportStore.isLoading(),
    }
  }

  handleGettingReportClick (e) {
    e.preventDefault()
    ReportAction.load()
    ReportService.getProductReport(
      this.state.selectedReportType,
      this.state.selectedDateType,
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate),
    )
  }

  render () {
    return (
      <section id="ReportComponent">
        <div className="report-form">
          <div className="form-inline">
            <label>レポート集類:&nbsp;</label>
            <Select className="report-type"
                    disabled={this.state.loading}
                    clearable={false}
                    options={this.reportTypes}
                    value={this.state.selectedReportType}
                    onChange={this._onReportTypeSelect}/>

            <label>集計期間:&nbsp;</label>
            <DateRangePicker readOnly
                             disabled={this.state.loading}
                             startDatePlaceholderText="開始日"
                             endDatePlaceholderText="完了日"
                             monthFormat="YYYY[年]MM"
                             phrases={{
                               closeDatePicker: '完了',
                               clearDates: 'クリア',
                             }}
                             startDate={this.state.selectedStartDate}
                             endDate={this.state.selectedEndDate}
                             focusedInput={this.state.focusedInput}
                             onDatesChange={this._onDateChange}
                             onFocusChange={this._onFocusChange}
                             isOutsideRange={() => false}
                             hideKeyboardShortcutsPanel
                             minimumNights={0}
                             showClearDates/>
            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計</LoadingButton>
          </div>
        </div>
        <div className="tbl-admin tbl-template">
          {
            (!this.state.errorMessage) ?
              (this.state.products === undefined ||
                this.state.products === null) ?
                '' :
                Object.keys(this.state.products).length > 0 ?
                  <ProductReportTable
                    products={this.state.products}
                    listTitle={this.state.listProductTitle}/> :
                  '検索結果は０件です。'
              : <span className="key-invalid-msg">{this.state.errorMessage}</span>
          }
        </div>
      </section>
    )
  }
}

ProductReportComponent.propTypes = {
  selectedStartDate: momentPropTypes.momentObj,
  selectedEndDate: momentPropTypes.momentObj,
}

export default ProductReportComponent
