import BaseStore from "../base/stores/BaseStore.jsx";
import ReportConstant from "./ReportConstant.jsx";

class ReportStore extends BaseStore {
  constructor () {
    super()
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null;
    this.loading = true;
    this.products = null;
    this.listProductTitle = [];
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true
        this.errorMessage = null
        this.templates = null
        this.products = null
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT:
        this.products = action.products
        this.listProductTitle = action.listTitle
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ERROR:
        this.errorMessage = action.message
        this.loading = false
        this.emitChange()
        break
      default:
        break
    }
  }

  getErrorMessage () {
    return this.errorMessage
  }

  getProducts () {
    return this.products
  }
  getListProductTitle() {
    return this.listProductTitle
  }

  isLoading () {
    return this.loading
  }
}

const reportStore = new ReportStore()

export default reportStore
