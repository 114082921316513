import React from "react";
import request from "reqwest";
import when from "when";
import LoginAction from "../../common/actions/AuthenticationAction.jsx";
import OrderAction from "./OrderAction.jsx";
import OrderConstant from "./OrderConstant.jsx";
import Constants from "../../common/constants/Constants.jsx";

class OrderService {
	loadOrders(orderFilter) {
		var url =
			OrderConstant.API.GET_ORDER_URL +
			"?currentPage=" +
			encodeURIComponent(orderFilter.currentPage) +
			"&pageSize=" +
			encodeURIComponent(orderFilter.pageSize);
		if (orderFilter.status && orderFilter.status !== OrderConstant.STATUS.ALL) {
			url += "&status=" + encodeURIComponent(orderFilter.status);
		}
		// if (orderFilter.orderByExShipDate) {
		// 	url +=
		// 		"&orderByExShipDate=" +
		// 		encodeURIComponent(orderFilter.orderByExShipDate);
		// }
		if (orderFilter.orderByDefaultDeliDate) {
			url +=
				"&orderByDefaultDeliDate=" +
				encodeURIComponent(orderFilter.orderByDefaultDeliDate);
		}
		if (orderFilter.keyword.trim()) {
			url += "&keyword=" + encodeURIComponent(orderFilter.keyword.trim());
		}
		when(
			request({
				url: url,
				method: "GET",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
			})
		).then(function (response) {
			switch (response.code) {
				case 200:
					OrderAction.loadedOrders(response.data, orderFilter);
					break;
				case 401:
					LoginAction.loginUser({});
					break;
				case 400:
					break;
				default:
					break;
			}
		});
	}

	getOrder(orderNumber) {
		OrderAction.showOrderDetail();
		when(
			request({
				url: OrderConstant.API.DETAIL_URL + "?orderNumber=" + orderNumber,
				method: "GET",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		).then(function (response) {
			switch (response.code) {
				case 200:
					OrderAction.detail(response.data);
					break;
				case 401:
					LoginAction.loginUser({});
					break;
				case 400:
					break;
				default:
					break;
			}
		});
	}

	hideOrderDetail() {
		OrderAction.hideOrderDetail();
	}

	changeOrderStatus(orderNumber, status) {
		when(
			request({
				url: OrderConstant.API.CHANGE_STATUS_URL.format(orderNumber, status),
				method: "PUT",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		).then(function (response) {
			switch (response.code) {
				case 200:
					OrderAction.changeOrderStatus(
						response.code,
						response.message,
						response.data
					);
					break;
				case 401:
					LoginAction.loginUser({});
					break;
				default:
					OrderAction.rollbackChangeOrderStatus(
						response.code,
						"ECサイトステータスを変更に失敗しました。",
						response.errorCode
					);
					break;
			}
		});
	}

	changeOrderShipment(orderNumber, shipId, ShipDate) {
		when(
			request({
				url: OrderConstant.API.CHANGE_SHIPMENT_INFO_URL.format(
					orderNumber,
					shipId,
					ShipDate
				),
				method: "PUT",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		).then(function (response) {
			switch (response.code) {
				case 200:
					OrderAction.changeShipmentInfo(
						response.code,
						response.message,
						response.data
					);
					break;
				case 401:
					LoginAction.logout();
					break;
				default:
					OrderAction.rollbackChangeOrderStatus(
						response.code,
						"ECサイトステータスを変更に失敗しました。",
						response.errorCode
					);
					break;
			}
		});
	}

	changeEcOrderStatus(orderNumber, status) {
		when(
			request({
				url: OrderConstant.API.CHANGE_STATUS_URL.format(orderNumber, status),
				method: "PUT",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		).then(function (response) {
			switch (response.code) {
				case 200:
					OrderAction.changeEcOrderStatus(
						response.code,
						response.message,
						response.data
					);
					break;
				case 401:
					LoginAction.loginUser({});
					break;
				default:
					OrderAction.rollbackChangeOrderStatus(
						response.code,
						"ECサイトステータスを変更に失敗しました。",
						response.errorCode
					);
					break;
			}
		});
	}

	synchronizeOrder(orderNumber) {
		when(
			request({
				url: OrderConstant.API.SYNCHRONIZE_URL.format(orderNumber),
				method: "GET",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		)
			.then(function (response) {
				switch (response.code) {
					case 200:
						OrderAction.synchronizeOrder(response.data);
						break;
					default:
						OrderAction.rollbackOrderSynchronization(response.code);
						break;
				}
			})
			.catch(function () {});
	}

	createImageMagicOrder(orderNumber, updatedAt) {
		when(
			request({
				url: OrderConstant.API.CREATE_IMAGEMAGIC_ORDER.format(
					orderNumber,
					updatedAt
				),
				method: "POST",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		)
			.then(function (response) {
				OrderAction.createImageMagicOrder(
					response.code,
					response.errorCode,
					response.data
				);
			})
			.catch(function () {});
	}

	verifyOrderPayment(orderNumber) {
		when(
			request({
				url: OrderConstant.API.VERIFY_ORDER_PAYMENT.format(orderNumber),
				method: "GET",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				type: "json",
			})
		)
			.then(function (response) {
				switch (response.code) {
					case 200:
						OrderAction.verifyOrderPayment(
							response.code,
							response.data.paymentStatus
						);
						break;
					case 500:
						OrderAction.verifyOrderPayment(response.code, null);
						break;
					default:
						OrderAction.verifyOrderPayment(response.code, null);
						break;
				}
			})
			.catch(function () {});
	}

	createDownloadReportLink(orderFilter) {
		if (
			((!orderFilter.minOrderDate || !orderFilter.maxOrderDate) &&
				(!orderFilter.minShipDate || !orderFilter.maxShipDate)) ||
			!orderFilter.status ||
			orderFilter.status.length === 0 ||
			!orderFilter.ecSiteIds ||
			orderFilter.ecSiteIds.length === 0
		) {
			throw "Illegal argument exception";
		}
		var url = OrderConstant.API.EXPORT_ORDER_REPORT + "?";
		var parameters = Array();
		if (!!orderFilter.minOrderDate && !!orderFilter.maxOrderDate) {
			parameters.push(
				"minOrderDate=" +
					encodeURIComponent(orderFilter.minOrderDate) +
					"&maxOrderDate=" +
					encodeURIComponent(orderFilter.maxOrderDate)
			);
		}
		if (!!orderFilter.minShipDate && !!orderFilter.maxShipDate) {
			parameters.push(
				"minShipDate=" +
					encodeURIComponent(orderFilter.minShipDate) +
					"&maxShipDate=" +
					encodeURIComponent(orderFilter.maxShipDate)
			);
		}
		for (var index in orderFilter.status) {
			parameters.push(
				"orderStatus=" + encodeURIComponent(orderFilter.status[index])
			);
		}
		for (var index in orderFilter.ecSiteIds) {
			parameters.push(
				"ecSiteIds=" + encodeURIComponent(orderFilter.ecSiteIds[index])
			);
		}
		return url + parameters.join("&");
	}

	selectOrders(orderNumbers, mode) {
		//mode = true: add
		//mode = false: remove
		mode
			? OrderAction.selectOrder(orderNumbers)
			: OrderAction.removeSelectedOrder(orderNumbers);
	}

	downloadOrderCsvByDate(targetDate, status) {
		let URL =
			OrderConstant.API.EXPORT_SHIPMENT_CSV +
			"?changeStatus=true&deliveryDate=" +
			targetDate;

		if (
			status == OrderConstant.STATUS.NEW ||
			status == OrderConstant.STATUS.CREATING
		) {
			URL += "&printStatus=" + status;
		}

		fetch(URL, {
			headers: {
				"Csrf-Token": "nocheck",
				Authorization: localStorage.getItem("token")
					? localStorage.getItem("token")
					: "",
			},
		}).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = `order_babyful_${targetDate.replaceAll("-", "")}.csv`;
				a.click();
				
				if (status == OrderConstant.STATUS.NEW) {
					window.location.reload();
				}
			});
		});
	}

	downloadCsvByRandomIds(randomIds) {
		if (!randomIds) return "javascript:void(0);";
		let parameters = [];
		randomIds.forEach(function (value) {
			parameters.push("randomIds[]=" + encodeURIComponent(value));
		});

		let URL =
			OrderConstant.API.EXPORT_SHIPMENT_CSV +
			"?changeStatus=true&" +
			parameters.join("&");

		fetch(URL, {
			headers: {
				"Csrf-Token": "nocheck",
				Authorization: localStorage.getItem("token")
					? localStorage.getItem("token")
					: "",
			},
		}).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = "order_babyful_download.csv";
				a.click();
			});
		});
	}

	uploadShippedCSV(selectedFile, orderProcessData) {
		const formData = new FormData();
		formData.append("shippedCsv", selectedFile);
		const url = OrderConstant.API.UPLOAD_FILE_SHIPPED_CSV + "?printCompany=maw";
		OrderAction.load();
		when(
			request({
				url: url,
				method: "POST",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				processData: false,
				data: formData,
			})
		)
			.then(function (response) {
				switch (response.code) {
					case Constants.RESPONSE_STATUS.SUCCESS:
						OrderAction.uploadCsvSuccess(orderProcessData);
						break;
					case Constants.RESPONSE_STATUS.UNAUTHORIZED:
						LoginAction.loginUser({});
						break;
					case Constants.RESPONSE_STATUS.BAD_REQUEST:
						OrderAction.badRequest(response.message);
						break;
					case Constants.RESPONSE_STATUS.SERVER_ERROR:
						OrderAction.badRequest(response.message);
						break;
					default:
						break;
				}
			})
			.catch(function (e) {})
			.done(() => {});
	}

	getShipmentProcessNumber(mycloShippedFileId) {
		let url = OrderConstant.API.GET_ORDERS_PROCESS;
		url =
			mycloShippedFileId !== null
				? (url +=
						"?mycloShippedFileId=" + encodeURIComponent(mycloShippedFileId))
				: url;
		when(
			request({
				url: url,
				method: "GET",
				crossOrigin: true,
				headers: {
					"Csrf-Token": "nocheck",
					Authorization: localStorage.getItem("token")
						? localStorage.getItem("token")
						: "",
				},
				processData: false,
			})
		)
			.then(function (response) {
				switch (response.code) {
					case Constants.RESPONSE_STATUS.SUCCESS:
						let data = response.data;
						break;
					case Constants.RESPONSE_STATUS.UNAUTHORIZED:
						LoginAction.loginUser({});
						break;
					case Constants.RESPONSE_STATUS.BAD_REQUEST:
						OrderAction.badRequest(response.message);
						break;
					case Constants.RESPONSE_STATUS.SERVER_ERROR:
						OrderAction.badRequest(response.message);
						break;
					default:
						break;
				}
			})
			.catch(function (e) {})
			.done(() => {});
	}
}

export default new OrderService();
