/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react'
import LoadingButtonComponent from '../../../../common/components/LoadingButtonComponent.jsx'
import FlashComponent from '../../../../common/components/FlashComponent.jsx'
import Constants from '../../../../common/constants/Constants.jsx'
import DateTimeFormatComponent from '../../../../common/components/DateTimeFormatComponent.jsx'
import OrderConstant from '../../../OrderConstant.jsx'
import OrderService from '../../../OrderService.jsx'
import OrderStore from '../../../OrderStore.jsx'
import RakutenStatusFormat from './RakutenStatusFormat.jsx'
import OrderStatusFormat
  from '../../../../common/components/order/OrderStatusFormat.jsx'

export default class StatusComponent extends React.Component {

  constructor (props) {
    super()
    this.state = this._getState(props)
    this._onChange = this._onChange.bind(this)
    this.toggleUpdate = this.toggleUpdate.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.synchronizeOrder = this.synchronizeOrder.bind(this)
    this.resetSynchronizationOrderResult = this.resetSynchronizationOrderResult.bind(
      this)
  }

  _onChange () {
    this.setState(this._getState(this.props))
  }

  _getState(props) {
    if (!!props.order) {
      return {
        crudMode: Constants.CRUD_MODE.READ,
        updateButtonTitle: '編集',
        selectedOrderStatus: props.order.status,
        isSynchronizingOrder: false,
        isCreatingImageMagicOrder: props.order.status ===
            OrderConstant.STATUS.PENDING_CREATION &&
            props.order.pendingCreationState !==
            OrderConstant.CREATION_STATE.DONE &&
            props.order.pendingCreationErrorMessage === '',
        orderSynchronizationResult: OrderStore.getSynchronizingOrderResult(),
      }
    } else {
      return {
        crudMode: Constants.CRUD_MODE.READ,
        updateButtonTitle: '編集',
        selectedOrderStatus: '',
        isSynchronizingOrder: false,
        isCreatingImageMagicOrder: false,
        orderSynchronizationResult: OrderStore.getSynchronizingOrderResult(),
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      selectedOrderStatus: nextProps.order.status,
    })
  }

  componentDidMount () {
    OrderStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    OrderStore.removeChangeListener(this._onChange)
  }

  toggleUpdate () {
    if (this.state.crudMode == Constants.CRUD_MODE.READ) {
      this.setState({
        crudMode: Constants.CRUD_MODE.UPDATE,
        updateButtonTitle: '保存',
      })
    } else {
      this.setState({
        crudMode: Constants.CRUD_MODE.READ,
        updateButtonTitle: '編集',
      })
      if (this.state.selectedOrderStatus != this.props.order.status) {
        OrderService.changeEcOrderStatus(this.props.order.orderNumber,
          this.state.selectedOrderStatus)
      }
    }
  }

  changeStatus (event) {
    this.state.selectedOrderStatus = event.target.value
  }

  synchronizeOrder () {
    this.setState({
      isSynchronizingOrder: true,
      orderSynchronizationResult: {},
    })
    OrderService.synchronizeOrder(this.props.order.orderNumber)
  }

  resetSynchronizationOrderResult () {
    this.setState({
      orderSynchronizationResult: {},
    })
  }
  processStatus (order) {
    return (
      <div>
        <div className="clearfix">
          <h3 className="babyful-title pull-left"></h3>
        </div>
        <h4 className="pull-left">注文基本情報</h4>
        <table className="table table-bordered">
          <tbody>
          <tr>
            <th width="190px">受注ID</th>
            <td>{order.randomId}</td>
            <th width="190px">注文日時</th>
            <td><DateTimeFormatComponent data={order.sendToPrintCompanyDate}/></td>
          </tr>
          <tr>
            <th width="190px">パートナー注文番号</th>
            <td colSpan="3">{order.orderNumber}</td>
          </tr>
          </tbody>
        </table>
      </div>)
  }

  renderSynchronizationOrderResult () {
    if (this.state.orderSynchronizationResult.httpCode) {
      var result = null
      switch (this.state.orderSynchronizationResult.httpCode) {
        case 200:
          result = {
            className: 'text-success',
            message: '注文情報が同期されました。',
          }
          break
        default:
          result = {
            className: 'text-danger',
            message: '注文同期に失敗しました。',
          }
          break
      }
      return <FlashComponent visible={true} timeout={5000}
                             className={result.className}
                             onHide={this.resetSynchronizationOrderResult}>
        {result.message}
      </FlashComponent>
    } else {
      return null
    }
  }

  loading () {
    return (<div>Loading data</div>)
  }

  get getStatus () {
    var order = this.props.order
    if (order == {} || !order)
      return this.loading()
    else
      return this.processStatus(order)
  }

  render () {
    return (this.getStatus)
  }
}