import React from "react";
import ReactDOM from "react-dom";
import { IndexRoute, Route, Router } from "react-router";
import createBrowserHistory from "history/lib/createBrowserHistory";
import Main from "./main/MainComponent.jsx";
import LoginComponent from "./login/LoginComponent.jsx";
import ProductReportComponent from "./report/component/product/ProductReportComponent.jsx";
import OrderPage from "./order/OrderPage.jsx";
import ReportPage from "./report/ReportPage.jsx";

let history = createBrowserHistory();

let routes = (
	<Route path="/" component={Main}>
		<IndexRoute component={LoginComponent} />
		<Route path="login" component={LoginComponent} />
		<Route path="orders" component={OrderPage} />
		<Route path="report" component={ReportPage} />
	</Route>
);

ReactDOM.render(
	<Router history={history}>{routes}</Router>,
	document.getElementById("body")
);

Array.prototype.groupBy = function (prop) {
	return this.reduce(function (groups, item) {
		const val = item[prop];
		groups[val] = groups[val] || [];
		groups[val].push(item);
		return groups;
	}, {});
};
